import { 
  IonButtons, 
  IonContent, 
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonLabel,
  IonIcon,
  IonItem,
  IonBackButton, IonFabButton, IonFab, IonSearchbar
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { addSharp } from 'ionicons/icons';
import HostApiUrl, { User, Congregation } from '../components/Global';
import './UserList.css';
import axios from 'axios';
import createQuery from '../components/CreateQuery';
import { RolesToArray, NameForRole } from '../components/Users';

interface UserCardProps {
  congregationId: number;
  users: Array<User>;
  congregations: Record<number, Congregation>;
};

const UserCards: React.FC<UserCardProps> = (props: UserCardProps) => {
  const congregations = props.congregations;
  const users = props.users.sort((a, b) => {
    if (a.congregation !== b.congregation) {
      const congA = (congregations[a.congregation] || {name: ''}).name;
      const congB = (congregations[b.congregation] || {name: ''}).name;

      return congA.localeCompare(congB, undefined, {numeric: true, sensitivity: 'base'});
    }

    return a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'});
  });

  const cards = users.map(user => {
    const href = "/ui/user/" + encodeURIComponent(user.id) + (props.congregationId? "?congregation=" + encodeURIComponent(props.congregationId): "");
    const congregation = congregations[user.congregation];

    return (
      <IonItem key={user.id} href={href}>
        <IonLabel>{user.name}</IonLabel>
        <IonLabel>{congregation ? congregation.name : ''}</IonLabel>
        <IonLabel>{RolesToArray(user.roles).reverse().map(r => NameForRole(r)).join(', ')}</IonLabel>
      </IonItem>
    );
  });

  return (
    <IonList>
      <IonItem>
        <IonLabel><b>Name</b></IonLabel>
        <IonLabel><b>Congregation</b></IonLabel>
        <IonLabel><b>Roles</b></IonLabel>
      </IonItem>
      {cards}
    </IonList>
  );
};

const UserList: React.FC = (props: any) => {
  const congregationId = Number(props.match.params.congregationId);
  const apiUrl = congregationId? "/api/congregation/" + congregationId + "/users": "/api/users";
  const [thisPath] = React.useState(window.location.pathname.slice());

  const scrollRef = React.useRef<any>(null);

  const history = useHistory();

  const [searchText, setSearchText] = React.useState("");

  const [addingUser, setAddingUser] = React.useState(false);
  const [users, setUsers] = React.useState<User []>([]);
  const [congregations, setCongregations] = React.useState<Record<number, Congregation>>({});
  const [title, setTitle] = React.useState("Users");

  useEffect(() => {
    const loadUsers = () => {
      axios.get(HostApiUrl() + "/api/congregations", {withCredentials: true})
      .then(result => {
        const congregationsObj: any = {};
        result.data.congregations!.forEach((cong: any) => congregationsObj[cong.id] = cong);
  
        setCongregations(congregationsObj);
        setTitle((congregationId? congregationsObj[congregationId].name + " ": "") + "Users");
  
        axios.get(HostApiUrl() + apiUrl, {withCredentials: true})
        .then(result => {
          setUsers(result.data.users!);
        }).catch(err => {
          
        });
      }).catch(err => {
        console.log(err);
  
        if (err.response && err.response.status === 401)
          history.replace("/ui/login?redirect=" + encodeURIComponent(thisPath));
      });
    };

    loadUsers();

    return history.listen((location, action) => {
      if (location.pathname === thisPath)
        loadUsers();
    });
  }, [history, thisPath]);

  useEffect(() => {
    if (addingUser) {
      history.push("/ui/user/new" + createQuery({congregation: congregationId || undefined}));
      setAddingUser(false);
    }
  });

  useEffect(() => {
    setTimeout(() => { /* Retry for 10 seconds to scroll the content to match the stored value, unless the user has scrolled since then */
      const cb = (tries: number) => {
        if (tries > 100)
          return;

        if (!scrollRef.current) {
          setTimeout(cb, 100, [Number(tries) + 1]);
          return;
        }
        
        scrollRef.current.getScrollElement().then((element: any) => {
          var scrollHeight: any = window.sessionStorage.getItem(thisPath + "/scrollheight");
          var scrollTop: any = window.sessionStorage.getItem(thisPath + "/scrolltop");

          if (!scrollHeight || !scrollTop)
            return;

          scrollHeight = +scrollHeight;
          scrollTop = +scrollTop;

          if (element.scrollHeight == scrollHeight)
            scrollRef.current.scrollToPoint(0, scrollTop);
          else
            setTimeout(cb, 100, [Number(tries) + 1]);
        }).catch(() => {
          setTimeout(cb, 100, [Number(tries) + 1]);
        })
      };

      window.requestAnimationFrame(() => {
        cb(1);
      });
    });
  }, [users]);

  const saveScrollPosition = (scroll: any) => {
    try {
      scroll.getScrollElement().then((element: any) => { 
        window.sessionStorage.setItem(thisPath + "/scrolltop", String(element.scrollTop));
        window.sessionStorage.setItem(thisPath + "/scrollheight", String(element.scrollHeight));
      });
    } catch (err) {console.log("Error saving scroll position", err)}
  };

  const filteredUsers = users.filter(user => user.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 || RolesToArray(user.roles).reverse().map(r => NameForRole(r)).join(', ').toLowerCase().indexOf(searchText.toLowerCase()) >= 0);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {congregationId? <IonBackButton defaultHref="/ui/congregations" />: <IonMenuButton />}
          </IonButtons>
          <IonTitle>{title + " (" + filteredUsers.length + ")"}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen ref={scrollRef} scrollEvents={true} onIonScrollEnd={e => saveScrollPosition(e.target)}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title + " (" + filteredUsers.length + ")"}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar placeholder="Search for user" debounce={500} value={searchText} onIonChange={e => setSearchText(e.detail.value!)} />
        <UserCards congregationId={congregationId} users={filteredUsers} congregations={congregations} />
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={e => setAddingUser(true)}>
            <IonIcon icon={addSharp}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default UserList;
