const SaveUser = (data: any) => {
  window.localStorage.setItem("id", data.id);
  window.localStorage.setItem("congregation", data.congregation);
  window.localStorage.setItem("roles", data.roles);
  window.localStorage.setItem("token", data.token);
};

const LoadUser = () : any => {
  var user: any = {};

  user.id = +(window.localStorage.getItem("id") || 0);
  user.congregation = +(window.localStorage.getItem("congregation") || 0);
  user.roles = +(window.localStorage.getItem("roles") || 0);
  user.token = window.localStorage.getItem("token") || "";

  return user;
};

const SaveFilter = (data: any) => {
  window.localStorage.setItem("filter/congregation", data.congregation || 0);
  window.localStorage.setItem("filter/territory", data.territory || 0);
  window.localStorage.setItem("filter/publisher", data.publisher || 0);
  window.localStorage.setItem("filter/text", data.text || "");
  window.localStorage.setItem("filter/mode", data.mode || 0);
  window.localStorage.setItem("filter/submode", data.submode || 0);
  window.localStorage.setItem("filter/date", data.date || "");
}

const LoadFilter = (): any => {
  var filter: any = {};

  filter.congregation = +(window.localStorage.getItem("filter/congregation") || 0);
  filter.territory = +(window.localStorage.getItem("filter/territory") || 0);
  filter.publisher = +(window.localStorage.getItem("filter/publisher") || 0);
  filter.text = window.localStorage.getItem("filter/text") || "";
  filter.mode = +(window.localStorage.getItem("filter/mode") || 0);
  filter.submode = +(window.localStorage.getItem("filter/submode") || 0);
  filter.date = window.localStorage.getItem("filter/date") || "";

  return filter;
}

export interface Column {
  name: string,
  width: number,
  align: 'start' | 'end' | 'default'
}

const DefaultColumns = (): Column [] => {
  const defaultWidth = 150;

  return [
    {name: 'Name', width: defaultWidth, align: 'default'},
    {name: 'Address', width: defaultWidth, align: 'default'},
    {name: 'Area', width: defaultWidth, align: 'default'},
    {name: 'Phone', width: defaultWidth, align: 'default'},
    {name: 'Listing', width: defaultWidth, align: 'start'},
    {name: 'Territory', width: defaultWidth, align: 'start'},
    {name: 'Publisher', width: defaultWidth, align: 'start'},
    {name: 'Demographics', width: defaultWidth, align: 'default'},
    {name: 'Status', width: defaultWidth, align: 'default'},
    {name: 'Research', width: defaultWidth, align: 'end'},
    {name: 'Checkout', width: defaultWidth, align: 'default'},
    {name: 'Checkin', width: defaultWidth, align: 'default'},
    {name: 'Notes', width: defaultWidth, align: 'default'},
    {name: 'Excluded', width: defaultWidth, align: 'default'},
    {name: 'Address Verified', width: defaultWidth, align: 'default'},
    {name: 'Name Verified', width: defaultWidth, align: 'default'},
    {name: 'Record Notes', width: defaultWidth, align: 'default'}
  ];
};

const LoadColumns = (): Column [] => {
  const columns = window.localStorage.getItem("columns");

  if (!columns)
    return [];

  const parsed = JSON.parse(columns);
  if (!Array.isArray(parsed))
    return [];

  return parsed.map(x => { 
    if (typeof x === "string") {
      return {
        name: x,
        width: 150,
        align: 'default'
      };
    }

    return { 
      name: String(x.name || ''), 
      width: Number(x.width), 
      align: x.align === 'start' ? 'start' :
             x.align === 'end' ? 'end' : 'default' 
    };
  });
}

const SaveColumns = (columns: Column []) => {
  window.localStorage.setItem("columns", JSON.stringify(columns));
}

const LoadItemsPerPage = (): number => {
  return +(window.localStorage.getItem("items_per_page") || 150);
}

const SaveItemsPerPage = (items_per_page: number) => {
  window.localStorage.setItem("items_per_page", String(items_per_page));
}

export { LoadUser, SaveUser, LoadFilter, SaveFilter, DefaultColumns, LoadColumns, SaveColumns, LoadItemsPerPage, SaveItemsPerPage };
export default SaveUser;