import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import HostApiUrl from '../components/Global';
import queryString from 'query-string';
import './Logout.css';
import axios from 'axios';

const Logout: React.FC = (props: any) => {
  const history = useHistory();
  const query = queryString.parse(window.location.search);
  const redirectUrl: string = query.redirect? query.redirect as string: "";

  useEffect(() => {
    axios.delete(HostApiUrl() + "/api/sessions")
    .then(res => {
      history.replace(redirectUrl? redirectUrl: "/ui/login");
    }).catch(err => {
      console.log(err);
      history.replace(redirectUrl? redirectUrl: "/ui/login");
    });
  }, []);

  return null;
};

export default Logout;
