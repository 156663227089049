import { 
  IonButtons, 
  IonContent, 
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonList,
  IonListHeader,
  IonLabel,
  IonAlert,
  IonToast,
  IonItem,
  IonInput,
  IonModal,
  IonButton,
  IonBackButton,
  IonLoading
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import HostApiUrl, { Address, Congregation, Territory, User, MimeTypeForFileExtension, TERRITORYHELPER_CLIENT_ID, SimplifyDate } from '../components/Global';
import ErrorMessages from '../components/ErrorMessages';
import { LoadUser } from '../components/Storage';
import { CopyModalContent } from './AddressList';
import './CongregationEdit.css';
import axios from 'axios';
import createQuery from '../components/CreateQuery';
import queryString from 'query-string';

const Shared: any = require('../shared/shared');

interface TerritoryStatistics {
  info: Territory;
  checkout: string;
  checkin: string;
  outstanding?: Array<User>;
};

interface CongregationStatistics {
  info: Congregation;
  available_phone: number;
  available_writing: number;
  checked_out: number;
  completed_phone: number;
  completed_writing: number;
  completed_phone_last_year: number;
  completed_writing_last_year: number;
  never_worked_writing: number;
  never_worked_phone: number;
  territories: Array<TerritoryStatistics>;
  total: number;
  users: Array<User>;
}

interface StatisticsViewProps {
  congregation: number;
};

const StatisticsView: React.FC<StatisticsViewProps> = (props: StatisticsViewProps) => {
  const [statistics, setStatistics] = React.useState<CongregationStatistics>({
    info: {id: 0, name: '', territory_helper_auth: false},
    available_phone: 0,
    available_writing: 0,
    checked_out: 0,
    completed_phone: 0,
    completed_writing: 0,
    completed_phone_last_year: 0,
    completed_writing_last_year: 0,
    never_worked_writing: 0,
    never_worked_phone: 0,
    territories: [],
    total: 0,
    users: []
  });
  const [outstanding, setOutstanding] = React.useState<TerritoryStatistics[]>([]);
  const [completed, setCompleted] = React.useState<TerritoryStatistics[]>([]);
  const [filter, setFilter] = React.useState<string>("");

  useEffect(() => {
    axios.get(HostApiUrl() + "/api/congregation/" + encodeURIComponent(props.congregation) + "/stats", {withCredentials: true})
    .then(result => {
      const stats: CongregationStatistics = result.data;

      setOutstanding(stats.territories.filter(territory => territory.checkout && territory.outstanding && territory.outstanding.length).sort((a, b) => (a.checkout > b.checkout? 1: 0) - (a.checkout < b.checkout? 1: 0)));
      setCompleted(stats.territories.filter(territory => territory.checkin).sort((a, b) => - /* Inverted sort, newest first */ ((a.checkin > b.checkin? 1: 0) - (a.checkin < b.checkin? 1: 0))));
      setStatistics(stats);
    }).catch(error => {

    });
  }, []);

  return (
    <>
      <div className="statistics-header"><IonLabel>Statistics</IonLabel></div>

      <IonCard>
        <IonItem>
          <IonLabel>Total</IonLabel>
          <IonLabel slot="end">{statistics.total} addresses</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Currently checked out</IonLabel>
          <IonLabel slot="end">{statistics.checked_out} addresses ({(100 * statistics.checked_out / statistics.total).toFixed(2)}%)</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Available</IonLabel>
          <IonLabel slot="end">{statistics.available_phone} phone, {statistics.available_writing} writing, {statistics.available_phone + statistics.available_writing} total ({(100 * (statistics.available_phone + statistics.available_writing) / statistics.total).toFixed(2)}%)</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Completed in last year</IonLabel>
          <IonLabel slot="end">{statistics.completed_phone_last_year} phone, {statistics.completed_writing_last_year} writing, {statistics.completed_phone_last_year + statistics.completed_writing_last_year} total ({(100 * (statistics.completed_phone_last_year + statistics.completed_writing_last_year) / statistics.total).toFixed(2)}%)</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Completed in all records</IonLabel>
          <IonLabel slot="end">{statistics.completed_phone} phone, {statistics.completed_writing} writing, {statistics.completed_phone + statistics.completed_writing} total ({(100 * (statistics.completed_phone + statistics.completed_writing) / statistics.total).toFixed(2)}%)</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Never worked</IonLabel>
          <IonLabel slot="end">{statistics.never_worked_phone} phone, {statistics.never_worked_writing} writing, {statistics.never_worked_phone + statistics.never_worked_writing} total ({(100 * (statistics.never_worked_phone + statistics.never_worked_writing) / statistics.total).toFixed(2)}%)</IonLabel>
        </IonItem>
      </IonCard>

      <div className="statistics-header"><IonLabel>Still Checked Out</IonLabel></div>

      {outstanding.length === 0? <div className="no-records"><IonLabel>There are no records to display.</IonLabel></div>:
        <IonCard>
          {/* {outstanding.length > 150? <div className="no-records"><IonLabel><i>More than 100 records shown, only showing the first 100</i></IonLabel></div>: null} */}
          <IonItem>
            <IonLabel>Filter</IonLabel>
            <IonInput className="publisher-filter-input" type="text" placeholder="Publisher or territory" value={filter} onIonChange={e => setFilter(e.detail.value!)} />
          </IonItem>
          <IonList>
            <IonListHeader>
              <IonLabel><b>Name</b></IonLabel>
              <IonLabel><b>Checkout</b></IonLabel>
              <IonLabel><b>Publishers</b></IonLabel>
            </IonListHeader>
            {outstanding
              .filter(territory => 
                filter.length === 0 || 
                Shared.fieldMatches(territory.info.name, filter) ||
                (territory.outstanding || []).findIndex(user => Shared.fieldMatches(user.name, filter)) >= 0)
              .map(territory => 
                <IonItem href={"/ui/territory/" + encodeURIComponent(territory.info.id) + "/addresses?back=/ui/congregation/" + encodeURIComponent(props.congregation)}>
                  <IonLabel>{territory.info.name}</IonLabel>
                  <IonLabel>{SimplifyDate(territory.checkout)}</IonLabel>
                  <IonLabel>{territory.outstanding!.map(user => user.name).join(', ')}</IonLabel>
                </IonItem>)}
          </IonList>
        </IonCard>
      }

      <div className="statistics-header"><IonLabel>Completed Territories</IonLabel></div>

      {completed.length === 0? <div className="no-records"><IonLabel>There are no records to display.</IonLabel></div>:
        <IonCard>
          {/* {completed.length > 150? <div className="no-records"><IonLabel><i>More than 150 records shown, only showing the first 150</i></IonLabel></div>: null} */}
          <IonList>
            <IonListHeader>
              <IonLabel><b>Name</b></IonLabel>
              <IonLabel><b>Checkout</b></IonLabel>
              <IonLabel><b>Checkin</b></IonLabel>
            </IonListHeader>
            {completed.map(territory => 
              <IonItem href={"/ui/territory/" + encodeURIComponent(territory.info.id) + "/addresses?back=/ui/congregation/" + encodeURIComponent(props.congregation)}>
                <IonLabel>{territory.info.name}</IonLabel>
                <IonLabel>{SimplifyDate(territory.checkout)}</IonLabel>
                <IonLabel>{SimplifyDate(territory.checkin)}</IonLabel>
              </IonItem>)}
          </IonList>
        </IonCard>
      }
    </>
  )
}

const CongregationEdit: React.FC = (props: any) => {
  const history = useHistory();
  const back = "/ui/congregations";
  const query = queryString.parse(window.location.search);
  const territoryHelperCode = query.code || '';
  const territoryHelperRevoke = query.revoke;

  const { id } = useParams<{ id: string; }>();
  const isNewCongregation = id.toLowerCase() == "new";

  const [rerender, setRerender] = React.useState(false);
  const [unmatched, setUnmatched] = React.useState<Record<number, Address>>({});
  const [congregation, setCongregation] = React.useState<Congregation>({id: 0, name: '', territory_helper_auth: false});

  const [loadingCongregation, setLoadingCongregation] = React.useState(true);
  const [somethingChanged, setSomethingChanged] = React.useState(false);

  const [showingDeleteModal, setShowingDeleteModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [merging, setMerging] = React.useState(false);
  const [error, setError] = React.useState("");

  const fileInput: any = React.useRef(null);

  const relogin = (history: any) => {
    history.replace("/ui/login?redirect=" + encodeURIComponent("/ui/congregation/" + id));
  }

  useEffect(() => {
    setLoadingCongregation(true);

    if (!isNewCongregation) {
      /* Get congregation and congregation information from server on mount */
      axios.get(HostApiUrl() + "/api/congregation/" + encodeURIComponent(id), {withCredentials: true})
      .then(result => {
        setCongregation(result.data);

        setTimeout(() => {
          setLoadingCongregation(false);
          setSomethingChanged(false);
        }, 0);
      }).catch(err => {
        console.log(err);

        if (err.response && err.response.status === 401)
          relogin(history);
        else
          setError(ErrorMessages.ApiGet);
        setLoadingCongregation(false);
        setSomethingChanged(false);
      });
    } else {
      setLoadingCongregation(false);
      setSomethingChanged(true);
    }

    if (territoryHelperCode || territoryHelperRevoke) {
      axios.put(HostApiUrl() + "/api/congregation/" + encodeURIComponent(id) + "/territory_helper", {code: territoryHelperRevoke ? '' : territoryHelperCode, uri: HostApiUrl() + window.location.pathname}, {withCredentials: true})
      .then(result => {
        window.location.search = '';
      }).catch(err => {
        console.log(err);

        if (err.response && err.response.status === 401)
          relogin(history);
        else
          setError(ErrorMessages.ApiSet);
        setLoadingCongregation(false);
        setSomethingChanged(false);
      })
    }
  }, []);

  useEffect(() => {
    if (rerender)
      setRerender(false);
  }, [rerender]);

  useEffect(() => {
    setSomethingChanged(true);
  }, [congregation]);

  useEffect(() => {
    if (submitting) {
      if (isNewCongregation) {
        axios.post(HostApiUrl() + "/api/congregations", congregation, {withCredentials: true})
        .then(res => {
          history.replace("/ui/congregation/" + encodeURIComponent(res.data.id)); /* Redirect so we edit the same congregation if the client edits again */
        }).catch(err => {
          if (err.response && err.response.status === 401)
            relogin(history);
          else
            setError(ErrorMessages.ApiSet);
          setSomethingChanged(true);
        });
      } else { /* Editing a currently existing congregation */
        axios.put(HostApiUrl() + "/api/congregation/" + encodeURIComponent(id), congregation, {withCredentials: true})
        .then(res => {
          setCongregation(res.data);

          setTimeout(() => {
            setSomethingChanged(false);
          }, 0);
        }).catch(err => {
          if (err.response && err.response.status === 401)
            relogin(history);
          else
            setError(ErrorMessages.ApiSet);
          setSomethingChanged(true);
        });
      }

      setSomethingChanged(false);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (merging) {
      const put = (data: any) => {
        axios.put(HostApiUrl() + "/api/congregation/" + encodeURIComponent(id) + "/addresses", {upload: data}, {withCredentials: true})
        .then(res => {
          var unmatchedAddresses: Address [] = res.data.unmatched;
          var unmatchedAddressesObject: Record<number, Address> = {};
          var id = 0;
          unmatchedAddresses.forEach(a => unmatchedAddressesObject[++id] = a); // Fake IDs since the addresses don't actually exist yet

          setLoadingCongregation(false);
          setError("Addresses were successfully merged");
          setUnmatched(unmatchedAddressesObject);
        }).catch(err => {
          setLoadingCongregation(false);
          console.log(err);

          if (err.response && err.response.status === 401)
            relogin(history);
          else
            setError(ErrorMessages.ApiSet);
        });
      }

      var uploadFile: any = null;

      if (fileInput.current.files && fileInput.current.files.length) {
        uploadFile = fileInput.current.files[0];
        const uploadFileName = String(uploadFile.name);

        let reader = new FileReader();
        if (!reader) {
          setError(ErrorMessages.FileRead);
          return;
        }

        setLoadingCongregation(true);

        reader.readAsBinaryString(uploadFile);

        reader.onloadend = () => {put({data: btoa(reader.result as string), mime: MimeTypeForFileExtension(uploadFileName.substring(uploadFileName.lastIndexOf('.')+1))})};
        reader.onerror = () => {setError(ErrorMessages.FileRead)};
      } else {
        setError(ErrorMessages.FileRead);
      }

      setMerging(false);
    };
  }, [merging]);

  useEffect(() => {
    if (deleting) {
      axios.delete(HostApiUrl() + "/api/congregation/" + encodeURIComponent(id), {withCredentials: true})
      .then(res => {
        history.replace(back);
      }).catch(err => {
        if (err.response && err.response.status === 401)
          relogin(history);
        else
          setError(ErrorMessages.ApiDelete);
      });

      setDeleting(false);
    }
  }, [deleting]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={back} />
          </IonButtons>
          <IonTitle>{isNewCongregation? "New ": congregation.name + " "}Congregation</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonAlert 
          isOpen={showingDeleteModal} 
          onDidDismiss={e => setShowingDeleteModal(false)} 
          cssClass="delete-modal" 
          header="Confirm Deletion"
          message={"Are you sure you want to delete " + (congregation.name || "this congregation") + "?"}
          buttons={[
            {
              text: 'Keep this congregation',
              role: 'cancel',
              handler: () => {setShowingDeleteModal(false);}
            },
            {
              text: 'Delete',
              handler: () => {setDeleting(true); setShowingDeleteModal(false);}
            }]}
          />
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{isNewCongregation? "New ": congregation.name + " "}Congregation</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard className="congregation">
          <IonItem>
            <IonLabel>Congregation Name</IonLabel>
            <IonInput className="congregation-input" type="text" placeholder="Congregation Name" value={congregation.name} onIonChange={e => setCongregation(Object.assign({}, congregation, {name: e.detail.value!}))} />
          </IonItem>
          <IonItem>
            <IonLabel>{congregation.territory_helper_auth ? 'Authorized with ' : ''}TerritoryHelper</IonLabel>
            <IonButton href={`https://territoryhelper.com/api/auth?response_type=code&client_id=${TERRITORYHELPER_CLIENT_ID}&redirect_uri=${encodeURIComponent(HostApiUrl() + window.location.pathname)}`}>
              Authorize
            </IonButton>
            {congregation.territory_helper_auth ? 
              <IonButton href={`${HostApiUrl() + window.location.pathname}?revoke=1`}>
                Revoke Authorization
              </IonButton> : null}
          </IonItem>
          <IonItem>
            <IonLabel>Congregation Record Download</IonLabel>
            <IonButton href={HostApiUrl() + "/api/congregation/" + encodeURIComponent(congregation.id) + "/download" + createQuery({token: LoadUser().token, flat: 1})}>Download (Flat)</IonButton>
            <IonButton href={HostApiUrl() + "/api/congregation/" + encodeURIComponent(congregation.id) + "/download" + createQuery({token: LoadUser().token, flat: 0})}>Download (Separated)</IonButton>
          </IonItem>
          {isNewCongregation? null:
            <IonItem>
              <IonLabel>Merge Addresses</IonLabel>
              <input className="actual-file-input" id="fileBrowse" type="file" ref={fileInput} onChange={e => setRerender(true)} />
              <IonLabel>{fileInput.current && fileInput.current.files && fileInput.current.files[0]? fileInput.current.files[0].name: null}</IonLabel>
              <IonButton onClick={e => document.getElementById("fileBrowse")?.click()}>Browse...</IonButton>
              <IonButton disabled={!fileInput.current || !fileInput.current.files || !fileInput.current.files[0]} onClick={e => setMerging(true)}>Merge</IonButton>
            </IonItem>}

          <div className="button-box">
            <IonButton disabled={isNewCongregation || submitting || deleting || showingDeleteModal} className="action-button" onClick={e => setShowingDeleteModal(true)}>Delete</IonButton>
            <IonButton disabled={!somethingChanged || submitting || deleting || showingDeleteModal} className="action-button" onClick={e => setSubmitting(true)}>Save</IonButton>
          </div>
        </IonCard>

        {isNewCongregation? null:
          <StatisticsView congregation={Number(id)} />}

        <IonLoading isOpen={loadingCongregation} />

        <IonModal cssClass="copy-modal" isOpen={Object.keys(unmatched).length !== 0} onDidDismiss={e => setUnmatched({})}>
          <CopyModalContent dismiss={() => {setUnmatched({})}} territories={{}} selectedAddresses={unmatched} />
        </IonModal>

        <IonToast isOpen={error.length !== 0} onDidDismiss={() => setError("")} duration={ErrorMessages.DefaultDuration} message={error} />
      </IonContent>
    </IonPage>
  );
};

export default CongregationEdit;
