import {
  IonPage,
  IonContent,
  IonLoading
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import HostApiUrl from '../components/Global';
import { SaveUser } from '../components/Storage';
import './LoginChecker.css';
import axios from 'axios';

const LoginChecker: React.FC = (props: any) => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
    axios.get(HostApiUrl() + "/api/session", {withCredentials: true})
    .then(result => {
      SaveUser(result.data);
      history.replace("/ui/profile");
    }).catch(err => {
      history.replace("/ui/login");
    });}, 0);
  }, []);

  return (<IonPage><IonContent fullscreen><IonLoading isOpen={true}></IonLoading></IonContent></IonPage>);
};

export default LoginChecker;