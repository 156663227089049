import { 
  IonButtons, 
  IonContent, 
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonList,
  IonLabel,
  IonButton,
  IonIcon,
  IonItem,
  IonBackButton, IonFabButton, IonFab
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { addSharp, settingsSharp } from 'ionicons/icons';
import HostApiUrl, { Territory, Congregation } from '../components/Global';
import './TerritoryList.css';
import axios from 'axios';
import createQuery from '../components/CreateQuery';

interface TerritoryCardProps {
  congregationId: number;
  territories: Array<Territory>;
  congregations: Record<number, Congregation>;
};

const TerritoryCards: React.FC<TerritoryCardProps> = (props: TerritoryCardProps) => {
  const territories = props.territories;
  const congregations = props.congregations;

  console.log(territories);

  const cards = territories.map(territory => {
    const query = props.congregationId? "?congregation=" + encodeURIComponent(props.congregationId): "";
    const href = "/ui/territory/" + encodeURIComponent(territory.id);

    return (
      <IonItem key={territory.id} href={href + "/addresses" + query}>
        <IonLabel>{territory.name}</IonLabel>
        <IonLabel>{territory.notes}</IonLabel>
        <IonButton slot="end" href={href + query}>
          <IonIcon slot="icon-only" title="Settings" icon={settingsSharp} />
        </IonButton>
      </IonItem>
    );
  });

  return (
    <IonList>
      <IonItem>
        <IonLabel><b>Name</b></IonLabel>
        <IonLabel><b>Notes</b></IonLabel>
      </IonItem>
      {cards}
    </IonList>
  );
};

const TerritoryList: React.FC = (props: any) => {
  const congregationId = Number(props.match.params.congregationId);
  const [thisPath] = React.useState(window.location.pathname.slice());

  const scrollRef = React.useRef<any>(null);

  const history = useHistory();

  const [searchText, setSearchText] = React.useState("");

  const [addingTerritory, setAddingTerritory] = React.useState(false);
  const [territories, setTerritories] = React.useState<Territory []>([]);
  const [congregations, setCongregations] = React.useState<Record<number, Congregation>>({});
  const [title, setTitle] = React.useState("Territories");

  const loadTerritories = () => {
    axios.get(HostApiUrl() + "/api/congregations", {withCredentials: true})
    .then(result => {
      const congregationsObj: any = {};
      result.data.congregations!.forEach((cong: any) => congregationsObj[cong.id] = cong);

      setCongregations(congregationsObj);
      setTitle((congregationId? congregationsObj[congregationId].name + " ": "") + "Territories");

      axios.get(HostApiUrl() + 
      (congregationId? "/api/congregation/" + encodeURIComponent(congregationId) + "/territories":
       "/api/territories"),
       {withCredentials: true})
      .then(result => {
        console.log(result);
        setTerritories(result.data.territories!.sort((a: Territory, b: Territory) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})));
      }).catch(err => {
        
      });
    }).catch(err => {
      console.log(err);

      if (err.response && err.response.status === 401)
        history.replace("/ui/login?redirect=" + encodeURIComponent("/ui/congregation/" + encodeURIComponent(congregationId) + "/territories"));
    });
  };

  useEffect(() => {
    loadTerritories();

    return history.listen((location, action) => {
      if (location.pathname === thisPath)
        loadTerritories();
    });
  }, []);

  useEffect(() => {
    if (addingTerritory) {
      history.push("/ui/territory/new" + createQuery({congregation: congregationId || undefined}));
      setAddingTerritory(false);
    }
  });

  useEffect(() => {
    setTimeout(() => { /* Retry for 10 seconds to scroll the content to match the stored value, unless the user has scrolled since then */
      const cb = (tries: number) => {
        if (tries > 100)
          return;

        if (!scrollRef.current) {
          setTimeout(cb, 100, [Number(tries) + 1]);
          return;
        }
        
        scrollRef.current.getScrollElement().then((element: any) => {
          var scrollHeight: any = window.sessionStorage.getItem(thisPath + "/scrollheight");
          var scrollTop: any = window.sessionStorage.getItem(thisPath + "/scrolltop");

          if (!scrollHeight || !scrollTop)
            return;

          scrollHeight = +scrollHeight;
          scrollTop = +scrollTop;

          if (element.scrollHeight == scrollHeight)
            scrollRef.current.scrollToPoint(0, scrollTop);
          else
            setTimeout(cb, 100, [Number(tries) + 1]);
        }).catch(() => {
          setTimeout(cb, 100, [Number(tries) + 1]);
        })
      };

      window.requestAnimationFrame(() => {
        cb(1);
      });
    });
  }, [territories]);

  const saveScrollPosition = (scroll: any) => {
    try {
      scroll.getScrollElement().then((element: any) => { 
        window.sessionStorage.setItem(thisPath + "/scrolltop", String(element.scrollTop));
        window.sessionStorage.setItem(thisPath + "/scrollheight", String(element.scrollHeight));
      });
    } catch (err) {console.log("Error saving scroll position", err)}
  };

  const filteredTerritories = territories.filter(territory => territory.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {congregationId? <IonBackButton defaultHref="/ui/congregations" />: <IonMenuButton />}
          </IonButtons>
          <IonTitle>{title + " (" + filteredTerritories.length + ")"}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen ref={scrollRef} scrollEvents={true} onIonScrollEnd={e => saveScrollPosition(e.target)}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{title + " (" + filteredTerritories.length + ")"}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar placeholder="Search for territory" debounce={500} value={searchText} onIonChange={e => setSearchText(e.detail.value!)} />
        <TerritoryCards congregationId={congregationId} territories={filteredTerritories} congregations={congregations} />
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={e => setAddingTerritory(true)}>
            <IonIcon icon={addSharp}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default TerritoryList;
