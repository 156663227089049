import { 
  IonContent, 
  IonHeader,
  IonFooter,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonLabel,
  IonInput,
  IonButton
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import HostApiUrl from '../components/Global';
import queryString from 'query-string';
import './Login.css';
import axios from 'axios';
import SaveUser from '../components/Storage';

const Login: React.FC = (props: any) => {
  const history = useHistory();
  const query = queryString.parse(window.location.search);
  const redirectUrl: string = query.redirect? query.redirect as string: "";

  const [loggingIn, setLoggingIn] = React.useState(false);
  const [errorOccurred, setErrorOccurred] = React.useState(false);
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  useEffect(() => {
    if (loggingIn) {
      axios.post(HostApiUrl() + "/api/session", {username: username, password: password})
      .then(res => {
        try {
          SaveUser(res.data);
        } catch (e) {}

        history.replace(redirectUrl? redirectUrl: "/ui/profile");
      }).catch(err => {
        if (err.response && err.response.status === 401) {
          console.log(err);
          setErrorOccurred(true);
        }
      });
      setLoggingIn(false);
    }
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Database Login</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen onKeyDown={(e) => {if (e.key === "Enter") setLoggingIn(true);}}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Database Login</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard className="login">
          <IonInput className="login-input" type="text" placeholder="Username" value={username} onIonChange={e => setUsername(e.detail.value!)} />
          <IonInput className="login-input" type="password" placeholder="Password" value={password} onIonChange={e => setPassword(e.detail.value!)} />
          {errorOccurred? <IonLabel className="login-error" color="danger">Incorrect username or password</IonLabel>: null}
          <IonButton className="login-button" disabled={loggingIn} onClick={e => {setLoggingIn(true); setErrorOccurred(false)}}>Log in</IonButton>
        </IonCard>
      </IonContent>

      <IonFooter className="footer">
        <IonToolbar>
          <IonLabel>This site uses cookies. By logging in you agree to our use and storing of cookies.</IonLabel>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Login;
