import { 
  IonButtons, 
  IonContent, 
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSearchbar,
  IonList,
  IonLabel,
  IonItem,
  IonFab,
  IonFabButton,
  IonIcon,
  IonButton
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { addSharp, peopleSharp, settingsSharp, listSharp } from 'ionicons/icons';
import HostApiUrl, { Congregation } from '../components/Global';
import './CongregationList.css';
import axios from 'axios';

interface CongregationCardProps {
  congregations: Array<Congregation>;
};

const CongregationCards: React.FC<CongregationCardProps> = (props: CongregationCardProps) => {
  const congregations = props.congregations;

  const cards = congregations.map(congregation => {
    const href = "/ui/congregation/" + encodeURIComponent(congregation.id);

    return (
      <IonItem key={congregation.id} href={href + "/addresses"}>
        <IonLabel>{congregation.name}</IonLabel>
        <IonButton slot="end" href={href + "/territories"}>
          <IonIcon slot="icon-only" title="Territories" icon={listSharp} />
        </IonButton>
        <IonButton slot="end" href={href + "/users"}>
          <IonIcon slot="icon-only" title="Publishers" icon={peopleSharp} />
        </IonButton>
        <IonButton slot="end" href={href}>
          <IonIcon slot="icon-only" title="Settings" icon={settingsSharp} />
        </IonButton>
      </IonItem>
    );
  });

  return (
    <IonList>
      <IonItem>
        <IonTitle><b>Name</b></IonTitle>
      </IonItem>
      {cards}
    </IonList>
  );
};

const CongregationList: React.FC = (props: any) => {
  const history = useHistory();

  const [searchText, setSearchText] = React.useState("");

  const [addingCongregation, setAddingCongregation] = React.useState(false);
  const [congregations, setCongregations] = React.useState<Congregation []>([]);

  const loadCongregations = () => {
    axios.get(HostApiUrl() + "/api/congregations", {withCredentials: true})
    .then(result => {
      setCongregations(result.data.congregations!.sort((a: Congregation, b: Congregation) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})));
    }).catch(err => {
      console.log(err);

      if (err.response && err.response.status === 401) {
        history.replace("/ui/login?redirect=" + encodeURIComponent("/ui/congregations"));
      }
    });
  };

  useEffect(() => {
    loadCongregations();

    return history.listen((location, action) => {
      if (location.pathname == "/ui/congregations")
        loadCongregations();
    });
  }, []);

  useEffect(() => {
    if (addingCongregation) {
      history.push("/ui/congregation/new");
      setAddingCongregation(false);
    }
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Congregations</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Congregations</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar placeholder="Search for congregation" debounce={500} value={searchText} onIonChange={e => setSearchText(e.detail.value!)} />
        <CongregationCards congregations={congregations.filter(congregation => congregation.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)} />
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={e => setAddingCongregation(true)}>
            <IonIcon icon={addSharp}></IonIcon>
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default CongregationList;
