import { 
  IonButtons, 
  IonContent, 
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonItem,
  IonIcon,
  IonLabel,
  IonAlert,
  IonToast,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonBackButton,
  IonCheckbox,
  IonDatetime,
  IonLoading,
  IonModal
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { trashSharp, checkmarkSharp } from 'ionicons/icons';
import HostApiUrl, { User, Record, Territory, Address, Congregation } from '../components/Global';
import queryString from 'query-string';
import createQuery from '../components/CreateQuery';
import BugFixes from '../components/BugFixes';
import ErrorMessages from '../components/ErrorMessages';
import './AddressEdit.css';
import axios from 'axios';
import { ExternalSiteModalContent } from './AddressCommon';

interface RecordEditProps {
  record: Record;
  publishers: User [];
  isLatestRecord: boolean;
  deleteRecord: (recordId: number) => void;
  saveRecord: (record: Record) => void;
}

const RecordEdit: React.FC<RecordEditProps> = (props: RecordEditProps) => {
  const [showingDeleteModal, setShowingDeleteModal] = React.useState(false);
  const [publisher, setPublisher] = React.useState(props.record.publisher);
  const [record, setRecord] = React.useState<Record>(props.record);
  const [dirty, setDirty] = React.useState(false);

  useEffect(() => {
    setRecord(props.record);
    setPublisher(props.record.publisher);
    setDirty(false);
  }, [props.record]);

  useEffect(() => {
    setDirty(dirty ||
            publisher !== props.record.publisher ||
            record.notes !== props.record.notes ||
            record.checkout !== props.record.checkout ||
            record.checkin !== props.record.checkin);
  }, [publisher, record]);

  var checkin_buttons: any[] = [];

  if (props.isLatestRecord) {
    checkin_buttons.push({
      text: 'Clear',
      handler: () => setRecord(Object.assign({}, record, {checkin: null}))
    });
  }

  checkin_buttons.push({
    text: 'Cancel',
    handler: () => {}
  })
  checkin_buttons.push({
    text: 'Done',
    handler: (e: any) => {setRecord(Object.assign({}, record, {checkin: e.year.text + '-' + e.month.text + '-' + e.day.text}))}
  });

  return (
    <IonCard>
      <IonAlert 
        isOpen={showingDeleteModal} 
        onDidDismiss={e => setShowingDeleteModal(false)} 
        cssClass="delete-modal" 
        header="Confirm Deletion"
        message={"Are you sure you want to delete this record?"}
        buttons={[
          {
            text: 'Keep this record',
            role: 'cancel',
            handler: () => {setShowingDeleteModal(false);}
          },
          {
            text: 'Delete',
            handler: () => {setShowingDeleteModal(false); props.deleteRecord(record.id);}
          }]}
        />

      <IonItem>
        <IonLabel>Publisher</IonLabel>
        <IonSelect slot="end" compareWith={BugFixes.compareWithIdShim} value={{id: publisher}} onIonChange={e => setPublisher(e.detail.value.id!)}>
          {props.publishers.map(publisher => 
            <IonSelectOption value={publisher}>{publisher.name}</IonSelectOption>
          )}
        </IonSelect>
      </IonItem>
      <IonItem>
        <IonLabel>Notes</IonLabel>
        <IonInput className="user-input" placeholder="Record-specific notes for publisher" value={record.notes} onIonChange={e => setRecord(Object.assign({}, record, {notes: e.detail.value!}))}></IonInput>
      </IonItem>
      <IonItem>
        <IonLabel>Checked Out</IonLabel>
        <IonDatetime slot="end" displayFormat="MM-DD-YYYY" value={record.checkout} onIonChange={e => setRecord(Object.assign({}, record, {checkout: e.detail.value!}))}></IonDatetime>
      </IonItem>
      <IonItem>
        <IonLabel>Checked In</IonLabel>
        <IonDatetime slot="end" displayFormat="MM-DD-YYYY" value={record.checkin} pickerOptions={{
          buttons: checkin_buttons
        }} onIonChange={e => setRecord(Object.assign({}, record, {checkin: e.detail.value!}))}></IonDatetime>
      </IonItem>
      <div className="button-box">
        <IonButton className="mini-action-button" slot="end" onClick={e => {setShowingDeleteModal(true)}}>
          <IonIcon slot="icon-only" icon={trashSharp} />
        </IonButton>
        <IonButton className="mini-action-button" slot="end" disabled={!dirty} onClick={e => {props.saveRecord(Object.assign({}, record, {publisher: publisher}))}}>
          <IonIcon slot="icon-only" icon={checkmarkSharp} />
        </IonButton>
      </div>
    </IonCard>
  )
};

interface RecordsEditProps {
  records: Record [];
  publishers: User [];
  deleteRecord: (recordId: number) => void;
  saveRecord: (record: Record) => void;
}

const RecordsEdit: React.FC<RecordsEditProps> = (props: RecordsEditProps) => {
  return (
    <>
      <div className="records-header"><IonLabel>Records</IonLabel></div>

      {props.records.length === 0? <div className="no-records"><IonLabel>There are no records to display.</IonLabel></div>: 
       props.records.map(record => 
        <RecordEdit isLatestRecord={record.id === props.records[0].id} record={record} publishers={props.publishers} deleteRecord={props.deleteRecord} saveRecord={props.saveRecord} />
      )}
    </>
  )
}

const StatusOptions = [
  '',
  // 'Returned once, not resent yet',
  // 'Returned once already, resent',
  // 'Returned twice already, no accurate names found',
  // 'Returned once, phone required, no mail at address',
  'Phone required, no mail at address',
  'No such number'
];

interface AddressFieldsProps {
  address: any;
  congregations?: Array<Congregation>;
  batch?: boolean;
  addressChanged: (address: any) => void;
  publishersChanged?: (publishers: User []) => void;
  territoriesChanged?: (territories: Territory []) => void;
};

const AddressFields: React.FC<AddressFieldsProps> = (props: AddressFieldsProps) => {
  const batchEdit = props.batch || false;
  const [territories, setTerritories] = React.useState<Array<any>>([]);
  const [publishers, setPublishers] = React.useState<Array<any>>([]);

  const [refreshingAddress, setRefreshingAddress] = React.useState(false);
  const [address, setAddress] = React.useState<any>({});
  const [status, setStatus] = React.useState("");
  const [congregation, setCongregation] = React.useState<number>(0);
  const [territory, setTerritory] = React.useState<number>(0);

  const [batchKeys, setBatchKeys] = React.useState<string []>([]);

  const checkUncheckBatchKey = (key: string) => {
    var a: any = Object.assign({}, address);
    const keys = batchKeys.filter(value => value !== key);

    if (!batchKeys.includes(key)) {
      keys.push(key);
      if (key === 'territory')
        a.territory = territory;
      else if (key === "address_verified" || key === "name_verified" || key === "excluded")
        a[key] = false;
      else
        a[key] = '';
    } else {
      delete a[key];
    }

    setBatchKeys(keys);
    setAddress(a);
  }

  const checkBatchKey = (key: string) => {
    if (!refreshingAddress && batchEdit && !batchKeys.includes(key))
      checkUncheckBatchKey(key);
  }

  useEffect(() => {
    setRefreshingAddress(true);
    setTimeout(() => setBatchKeys([]), 0);
  }, []);

  useEffect(() => {
    setRefreshingAddress(true);
  }, [props.address]);

  useEffect(() => {
    if (refreshingAddress) {
      setAddress(props.address);
      setStatus(props.address.status || '');
      setCongregation(props.address.congregation);
      setTerritory(Number(props.address.territory || 0));

      setTimeout(() => {setRefreshingAddress(false)}, 0);
    }
  }, [refreshingAddress]);

  useEffect(() => {
    checkBatchKey('status');
  }, [status]);

  useEffect(() => {
    checkBatchKey('territory');
  }, [territory]);

  useEffect(() => {
    if (!refreshingAddress) {
      var obj = Object.assign({}, address, {status: status, territory: territory});

      if (batchEdit) {
        var newObj: any = {};
        batchKeys.forEach(key => newObj[key] = obj[key]);
        obj = newObj;
      }

      obj.congregation = congregation;
      props.addressChanged(obj);
    }
  }, [address, status, congregation, territory]);

  useEffect(() => {
    if (props.publishersChanged)
      props.publishersChanged(publishers);
  }, [publishers]);

  useEffect(() => {
    if (props.territoriesChanged)
      props.territoriesChanged(territories);
  }, [territories]);

  useEffect(() => {
    if (congregation) {
      axios.get(HostApiUrl() + "/api/congregation/" + encodeURIComponent(congregation) + "/territories", {withCredentials: true})
      .then(result => {
        setTerritories(result.data.territories!.sort((a: Territory, b: Territory) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})));
      }).catch(err => {
        console.log(err);
      });

      axios.get(HostApiUrl() + "/api/congregation/" + encodeURIComponent(congregation) + "/users", {withCredentials: true})
      .then(result => {
        setPublishers(result.data.users!.sort((a: User, b: User) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})));
      }).catch(err => {
        console.log(err);
      });
    }
  }, [congregation]);

  return (
    <>
      <IonItem>
        {batchEdit? <IonCheckbox slot="start" checked={batchKeys.includes('name')} onClick={e => checkUncheckBatchKey('name')} />: null}
        <IonLabel slot="start">Name</IonLabel>
        <IonInput className="user-input" type="text" placeholder="Householder Name" value={address.name || ''} onIonChange={e => {checkBatchKey('name'); setAddress(Object.assign({}, address, {name: String(e.detail.value!)}))}} />
      </IonItem>
      <IonItem>
        {batchEdit? <IonCheckbox slot="start" checked={batchKeys.includes('address')} onClick={e => checkUncheckBatchKey('address')} />: null}
        <IonLabel slot="start">Address</IonLabel>
        <IonInput className="user-input" type="text" placeholder="Address" value={address.address || ''} onIonChange={e => {checkBatchKey('address'); setAddress(Object.assign({}, address, {address: String(e.detail.value!)}))}} />
      </IonItem>
      <IonItem>
        {batchEdit? <IonCheckbox slot="start" checked={batchKeys.includes('area')} onClick={e => checkUncheckBatchKey('area')} />: null}
        <IonLabel slot="start">Area</IonLabel>
        <IonInput className="user-input" type="text" placeholder="City, State, and Zip" value={address.area || ''} onIonChange={e => {checkBatchKey('area'); setAddress(Object.assign({}, address, {area: String(e.detail.value!)}))}} />
      </IonItem>
      <IonItem>
        {batchEdit? <IonCheckbox slot="start" checked={batchKeys.includes('phone')} onClick={e => checkUncheckBatchKey('phone')} />: null}
        <IonLabel slot="start">Phone(s)</IonLabel>
        <IonInput className="user-input" type="text" placeholder="Phone(s)" value={address.phone || ''} onIonChange={e => {checkBatchKey('phone'); setAddress(Object.assign({}, address, {phone: String(e.detail.value!)}))}} />
      </IonItem>
      <IonItem>
        {batchEdit? <IonCheckbox slot="start" checked={batchKeys.includes('name_verified')} onClick={e => checkUncheckBatchKey('name_verified')} />: null}
        <IonLabel>Name Verified</IonLabel>
        <IonCheckbox title="Name Verified" checked={address.name_verified} onClick={e => {checkBatchKey('name_verified'); setAddress(Object.assign({}, address, {name_verified: !address.name_verified}))}} />
      </IonItem> 
      <IonItem>
        {batchEdit? <IonCheckbox slot="start" checked={batchKeys.includes('address_verified')} onClick={e => checkUncheckBatchKey('address_verified')} />: null}
        <IonLabel>Address Verified</IonLabel>
        <IonCheckbox title="Address Verified" checked={address.address_verified} onClick={e => {checkBatchKey('address_verified'); setAddress(Object.assign({}, address, {address_verified: !address.address_verified}))}} />
      </IonItem> 
      <IonItem>
        {batchEdit? <IonCheckbox slot="start" checked={batchKeys.includes('excluded')} onClick={e => checkUncheckBatchKey('excluded')} />: null}
        <IonLabel>Excluded</IonLabel>
        <IonCheckbox title="Excluded" checked={address.excluded} onClick={e => {checkBatchKey('excluded'); setAddress(Object.assign({}, address, {excluded: !address.excluded}))}} />
      </IonItem>
      <IonItem>
        {batchEdit? <IonCheckbox slot="start" checked={batchKeys.includes('demographic')} onClick={e => checkUncheckBatchKey('demographic')} />: null}
        <IonLabel slot="start">Demographics</IonLabel>
        <IonInput className="user-input" type="text" placeholder="Demographics" value={address.demographic || ''} onIonChange={e => {checkBatchKey('demographic'); setAddress(Object.assign({}, address, {demographic: String(e.detail.value!)}))}} />
      </IonItem>
      <IonItem>
        {batchEdit? <IonCheckbox slot="start" checked={batchKeys.includes('status')} onClick={e => checkUncheckBatchKey('status')} />: null}
        <IonLabel slot="start">Status</IonLabel>
        <IonSelect className="select-select" interfaceOptions={{cssClass: "select-select"}} slot="end" value={status} onIonChange={e => {setStatus(e.detail.value!)}}>
          {StatusOptions.map(statusOption => 
            <IonSelectOption value={statusOption}>{statusOption}</IonSelectOption>
          )}
        </IonSelect>
      </IonItem>
      <IonItem>
        {batchEdit? <IonCheckbox slot="start" checked={batchKeys.includes('notes')} onClick={e => checkUncheckBatchKey('notes')} />: null}
        <IonLabel slot="start">Notes</IonLabel>
        <IonInput className="user-input" type="text" placeholder="Notes" value={address.notes || ''} onIonChange={e => {checkBatchKey('notes'); setAddress(Object.assign({}, address, {notes: String(e.detail.value!)}))}} />
      </IonItem>
      {props.congregations && !batchEdit?
        <IonItem>
          <IonLabel>Congregation</IonLabel>
          {/* compareWith here is a workaround for these IonSelect bugs:
            *
            * https://github.com/ionic-team/ionic-framework/issues/19324 
            * https://github.com/ionic-team/ionic-framework/issues/17225 
            */}
          <IonSelect slot="end" compareWith={BugFixes.compareWithIdShim}
                      value={{id: congregation}} onIonChange={e => setCongregation(Number(e.detail.value.id!))}>
            {props.congregations.map(cong => {
              return (<IonSelectOption value={cong}>{cong.name}</IonSelectOption>)
            })}
          </IonSelect>
        </IonItem>: null}
      <IonItem>
        {batchEdit? <IonCheckbox slot="start" checked={batchKeys.includes('territory')} onClick={e => checkUncheckBatchKey('territory')} />: null}
        <IonLabel>Territory</IonLabel>
        {/* compareWith here is a workaround for these IonSelect bugs:
          *
          * https://github.com/ionic-team/ionic-framework/issues/19324 
          * https://github.com/ionic-team/ionic-framework/issues/17225 
          */}
        <IonSelect slot="end" compareWith={BugFixes.compareWithIdShim} 
                    value={{id: territory}} onIonChange={e => {setTerritory(Number(e.detail.value.id!))}}>
          {territories.map(terr => {
            return (<IonSelectOption value={terr}>{terr.name}</IonSelectOption>)
          })}
        </IonSelect>
      </IonItem>
    </>
  );
}

interface AddressEditProps {
  congregation?: Number;
  territory?: Number;
  back?: string;
  batch?: boolean;
};

const AddressEdit: React.FC<AddressEditProps> = (props: AddressEditProps) => {
  const history = useHistory();
  const query = queryString.parse(window.location.search);
  const qBack = query.back || props.back;
  const congregationId: number = Number(query.congregation || props.congregation);
  const territoryId: number = Number(query.territory || props.territory);
  const batchEdit: boolean = props.batch || false;
  const back: string | null = qBack? String(qBack):
                              territoryId? "/ui/territory/" + encodeURIComponent(territoryId) + "/addresses":
                              congregationId? "/ui/congregation/" + encodeURIComponent(congregationId) + "/addresses": null;

  const { id } = useParams<{ id: string; }>();
  const isNewAddress = id.toLowerCase() === "new";

  const [publishers, setPublishers] = React.useState<User []>([]);
  const [congregations, setCongregations] = React.useState<Congregation []>([]);
  const [address, setAddress] = React.useState<Address>({id: isNewAddress? 0: Number(id), congregation: 0, territory: 0, name: "", address: "", area: "", name_verified: false, address_verified: false, excluded: false, phone: "", demographic: "", status: "", notes: "", records: undefined});

  const [loadingAddress, setLoadingAddress] = React.useState(true);
  const [somethingChanged, setSomethingChanged] = React.useState(false);
  const [ignoreNextAddressUpdate, setIgnoreNextAddressUpdate] = React.useState(false);

  const [showingDeleteModal, setShowingDeleteModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState("");

  const [addressToResearch, setAddressToResearch] = React.useState<Address | null>(null);

  const relogin = (history: any) => {
    history.replace("/ui/login?redirect=" + encodeURIComponent("/ui/address/" + encodeURIComponent(id) + createQuery({congregation: congregationId || undefined, territory: territoryId || undefined})));
  };

  const deleteRecord = (recordId: number) => {
    axios.delete(HostApiUrl() + "/api/record/" + encodeURIComponent(recordId), {withCredentials: true})
    .then(result => {
      const records = address.records!.filter(r => r.id !== recordId);

      setIgnoreNextAddressUpdate(true);
      setAddress(Object.assign({}, address, {records: records}));
    }).catch(err => {
      if (err.response && err.response.status === 401)
        relogin(history);
      else
        setError(ErrorMessages.ApiDelete);
    });
  };

  const saveRecord = (record: Record) => {
    axios.put(HostApiUrl() + "/api/record/" + encodeURIComponent(record.id), record, {withCredentials: true})
    .then(result => {
      var records = address.records!.slice();

      var found = records.findIndex(r => r.id === record.id);
      if (found >= 0)
        records[found] = record;

      setIgnoreNextAddressUpdate(true);
      setAddress(Object.assign({}, address, {records: records}));
    }).catch(err => {
      if (err.response && err.response.status === 401)
        relogin(history);
      else
        setError(ErrorMessages.ApiSet);
    });
  };

  const loadAddress = () => {
    setLoadingAddress(true);

    /* Get congregation and address information from server on mount */
    axios.get(HostApiUrl() + "/api/congregations", {withCredentials: true})
    .then(result => {
      setCongregations(result.data.congregations!.sort((a: Congregation, b: Congregation) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})));
    }).catch(err => {
      if (err.response && err.response.status === 401)
        relogin(history);
      else
        setError(ErrorMessages.ApiGet);
      setLoadingAddress(false);
      setSomethingChanged(false);
    });

    if (!isNewAddress) {
      axios.get(HostApiUrl() + "/api/address/" + encodeURIComponent(id), {withCredentials: true})
      .then(res => {
        console.log(res);
        setAddress(res.data);

        setTimeout(() => {
          setLoadingAddress(false);
          setSomethingChanged(false);
        }, 0);
      }).catch(err => {
        console.log(err);
        setError(ErrorMessages.ApiGet);
        setLoadingAddress(false);
        setSomethingChanged(false);
      });
    } else if (congregationId) {
      setAddress(Object.assign({}, address, {congregation: congregationId, territory: territoryId}));
      setLoadingAddress(false);
      setSomethingChanged(false);
    }
  };

  useEffect(() => {
    if (batchEdit)
      return;

    const thisUrl = window.location.pathname;

    loadAddress();

    return history.listen((location, action) => {
      if (location.pathname === thisUrl)
        loadAddress();
    });
  }, []);

  useEffect(() => {
    if (submitting) {
      if (isNewAddress) {
        axios.post(HostApiUrl() + "/api/addresses", address, {withCredentials: true})
        .then(res => {
          history.replace("/ui/address/" + encodeURIComponent(res.data.id) + createQuery({congregation: congregationId || undefined, territory: territoryId || undefined})); /* Redirect so we edit the same address if the client edits again */
        }).catch(err => {
          if (err.response && err.response.status === 401)
            relogin(history);
          else
            setError(ErrorMessages.ApiSet);
          setSomethingChanged(true);
        });
      } else { /* Editing a currently existing territory */
        axios.put(HostApiUrl() + "/api/address/" + encodeURIComponent(id), address, {withCredentials: true})
        .then(res => {
          setAddress(Object.assign({}, res.data, {records: address.records}));

          setTimeout(() => {
            setSomethingChanged(false);
          }, 0);
        }).catch(err => {
          if (err.response && err.response.status === 401)
            relogin(history);
          else
            setError(ErrorMessages.ApiSet);
          setSomethingChanged(true);
        });
      }

      setSomethingChanged(false);
      setSubmitting(false);
    }
  }, [submitting]);

  useEffect(() => {
    if (deleting) {
      axios.delete(HostApiUrl() + "/api/address/" + encodeURIComponent(id), {withCredentials: true})
      .then(res => {
        if (back)
          history.replace(back);
      }).catch(err => {
        if (err.response && err.response.status === 401)
          relogin(history);
        else
          setError(ErrorMessages.ApiDelete);
      });

      setDeleting(false);
    }
  }, [deleting]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {back? <IonBackButton defaultHref={back} />: <IonMenuButton />}
          </IonButtons>
          <IonTitle>{isNewAddress? "New ": null}Address {address.address}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonAlert 
          isOpen={showingDeleteModal} 
          onDidDismiss={e => setShowingDeleteModal(false)} 
          cssClass="delete-modal" 
          header="Confirm Deletion"
          message={"Are you sure you want to delete " + (address.address || "this address") + " and all address records associated with it?"}
          buttons={[
            {
              text: 'Keep this address',
              role: 'cancel',
              handler: () => {setShowingDeleteModal(false);}
            },
            {
              text: 'Delete',
              handler: () => {setDeleting(true); setShowingDeleteModal(false);}
            }]}
          />
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{isNewAddress? "New ": null}Address {address.address}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard className="user">
          <AddressFields address={address} congregations={congregations} addressChanged={(newAddress: any) => {
            setAddress(newAddress);
            if (!ignoreNextAddressUpdate)
              setSomethingChanged(true);
            setIgnoreNextAddressUpdate(false);
          }} publishersChanged={(newPublishers: User []) => {
            setPublishers(newPublishers);
          }}/>
          <div className="button-box">
            <IonButton disabled={isNewAddress || submitting || deleting || showingDeleteModal} className="action-button" onClick={e => setShowingDeleteModal(true)}>Delete</IonButton>
            <IonButton disabled={(!isNewAddress && !somethingChanged) || submitting || deleting || showingDeleteModal} className="action-button" onClick={e => setSubmitting(true)}>Save</IonButton>
            <IonButton className="action-button" onClick={e => setAddressToResearch(address)}>Research</IonButton>
          </div>
        </IonCard>

        {address.records? <RecordsEdit records={address.records} publishers={publishers} deleteRecord={deleteRecord} saveRecord={saveRecord} />: null}

        <IonLoading isOpen={loadingAddress}></IonLoading>

        <IonModal cssClass="research-modal" isOpen={addressToResearch !== null} onDidDismiss={e => setAddressToResearch(null)}>
          <ExternalSiteModalContent address={addressToResearch} dismiss={() => setAddressToResearch(null)}></ExternalSiteModalContent>
        </IonModal>

        <IonToast isOpen={error.length !== 0} onDidDismiss={() => setError("")} duration={ErrorMessages.DefaultDuration} message={error} />
      </IonContent>
    </IonPage>
  );
};

export default AddressEdit;

export {
  AddressFields
};