// Not currently using ID
const TERRITORYHELPER_CLIENT_ID = '5edbefab5dd7405abe6fda68f878e386';

const HostApiUrl = () => {
  return window.location.protocol + "//" + window.location.hostname + (window.location.port? ":" + window.location.port: "");
};

const ObjectMap = (object: any, predicate: (key: any, value: any, object: any) => any = (key, value) => {return value;}): any [] => {
  if (typeof object !== 'object')
    return object;

  var result = [];
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      result.push(predicate(key, object[key], object));
    }
  }

  return result;
};

const SimplifyDate = (date: string | null): string | null => {
  return date && date.split('T')[0];
};

const MimeTypeForFileExtension = (extension: string): string => {
  const types: any = {
    "csv": "text/csv",
    "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  };

  return types[extension];
}

export interface User {
  id: number;
  name: string;
  username: string;
  email: string;
  congregation: number;
  roles: number;
  last_seen?: string;
};

export interface Congregation {
  id: number;
  name: string;
  territory_helper_auth: boolean;
};

export interface Territory {
  id: number;
  congregation: number;
  name: string;
  notes: string;
};

export interface Record {
  id: number;
  congregation: number;
  territory: number;
  address: number;
  publisher: number;
  notes: string;
  checkout: string | null;
  checkin: string | null;
}

export interface Address {
  id: number;
  congregation: number;
  territory: number;
  name: string;
  address: string;
  area: string;
  name_verified: boolean;
  address_verified: boolean;
  excluded: boolean;
  phone: string;
  demographic: string;
  status: string;
  notes: string;
  records: Record[] | undefined;
}

export enum ExternalAddressService {
  FastPeopleSearch,
  TruePeopleSearch,
  Spokeo,
  GoogleMaps,
  GoogleMapsEmbedded,
  BingMaps,
  AppleMaps,
}

const PrepareForUrl = (addressPart: string) => {
  return addressPart.replace(/\/|,/g, '').split(' ').join('-');
};

const UrlForAddress = (address: Address | undefined | null, service: ExternalAddressService) => {
  if (!address)
    return '';

  const combinedAddress = address.address + ', ' + address.area;

  switch (service) {
    case ExternalAddressService.FastPeopleSearch:   return "https://fastpeoplesearch.com/address/" + encodeURIComponent(PrepareForUrl(address.address) + '_' + PrepareForUrl(address.area));
    case ExternalAddressService.TruePeopleSearch:   return "https://truepeoplesearch.com/resultaddress?streetaddress=" + encodeURIComponent(address.address) + "&citystatezip=" + encodeURIComponent(address.area);
    case ExternalAddressService.Spokeo:             return "https://spokeo.com/search?q=" + encodeURIComponent(combinedAddress);
    case ExternalAddressService.GoogleMaps:         return "https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(combinedAddress);
    case ExternalAddressService.GoogleMapsEmbedded: return "https://www.google.com/maps/embed/v1/place?key=AIzaSyBw04R9Fypxch5oXSOcI3AmuAtD5X6MIV8&q=" + encodeURIComponent(combinedAddress);
    case ExternalAddressService.BingMaps:           return "https://bing.com/maps/default.aspx?where1=" + encodeURIComponent(combinedAddress);
    case ExternalAddressService.AppleMaps:          return "https://maps.apple.com/?q=" + encodeURIComponent(combinedAddress);
    default: return '';
  }
};

export { ObjectMap, MimeTypeForFileExtension, UrlForAddress, SimplifyDate, TERRITORYHELPER_CLIENT_ID };
export default HostApiUrl;