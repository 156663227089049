import { 
  IonButtons, 
  IonContent, 
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonList,
  IonLabel,
  IonSearchbar,
  IonSelect,
  IonIcon,
  IonLoading,
  IonModal,
  IonToast,
  IonItem,
  IonBackButton, 
  IonFabButton, 
  IonFab, 
  IonCheckbox, 
  IonButton, 
  IonSelectOption,
  IonReorderGroup,
  IonReorder
} from '@ionic/react';
import { ItemReorderEventDetail } from '@ionic/core';
// import DataTable from 'react-data-table-component';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { addSharp, closeSharp, linkSharp, arrowForwardSharp, squareOutline, checkbox } from 'ionicons/icons';
import HostApiUrl, { ObjectMap, User, Territory, Address, Congregation, UrlForAddress, ExternalAddressService, SimplifyDate } from '../components/Global';
import queryString from 'query-string';
import createQuery from '../components/CreateQuery';
import ErrorMessages from '../components/ErrorMessages';
import { UserOnlineString, UsersOnlineWidget } from '../components/UsersOnline';
import { LoadFilter, SaveFilter, Column, DefaultColumns, LoadColumns, SaveColumns } from '../components/Storage';
import { AddressFields } from './AddressEdit';
import axios from 'axios';
import BugFixes from '../components/BugFixes';
import ResizeObserver from 'rc-resize-observer';

import './AddressList.css';
import '../components/UsersOnline.css';
import { ExternalSiteModalContent } from './AddressCommon';

const Shared: any = require('../shared/shared');
const addressSortFirst = Shared.addressSortFirst;

const anyFieldMatches = (territories: Record<number, Territory>, publishers: Record<number, User>, address: Address, search: string): boolean => {
  return Shared.fieldMatches((territories[address.territory] || {name: ""}).name, search) ||
         Shared.fieldMatches((publishers[(address.records && address.records[0]? address.records[0].publisher: 0)] || {name: ""}).name, search) ||
         Shared.fieldMatches(address.name, search) ||
         Shared.fieldMatches(address.address, search) ||
         Shared.fieldMatches(address.area, search) ||
         Shared.fieldMatches(address.phone, search) ||
         Shared.fieldMatches(address.demographic, search) ||
         Shared.fieldMatches(address.status, search) ||
         Shared.fieldMatches(address.notes, search) ||
         Shared.addressesEqual(address.address + ", " + address.area, search + ", " + address.area);
}

const oldestSort = (a: Address, b: Address, inverted: boolean) => {
  const multiplier = inverted? -1: 1;
  const aCheckin = (a.records!.length && a.records![0].checkin) || undefined;
  const bCheckin = (b.records!.length && b.records![0].checkin) || undefined;

  if (aCheckin === bCheckin) { // Checkin equal or nonexistent
    const aCheckout = (a.records!.length && a.records![0].checkout) || undefined;
    const bCheckout = (b.records!.length && b.records![0].checkout) || undefined;

    if (aCheckout === undefined && bCheckout === undefined)
      return addressSortFirst(a, b);
    else if (aCheckout === undefined)
      return -1 * multiplier;
    else if (bCheckout === undefined)
      return 1 * multiplier;

    const cmp = ((aCheckout > bCheckout)? 1: 0) - ((aCheckout < bCheckout)? 1: 0);
    if (cmp)
      return cmp * multiplier;

    return addressSortFirst(a, b);
  }

  if (aCheckin === undefined) // A never worked, but B must have been, since A != B
    return -1 * multiplier;
  else if (bCheckin === undefined) // B never worked, but A must have been, since A != B
    return 1 * multiplier;

  if (aCheckin && bCheckin) { // Both A and B have existing records and are both checked in
    const cmp = ((aCheckin > bCheckin)? 1: 0) - ((aCheckin < bCheckin)? 1: 0);
    if (cmp)
      return cmp * multiplier;

    return addressSortFirst(a, b);
  } else if (aCheckin) // A checked in but B still checked out
    return -1 * multiplier;
  else if (bCheckin) // B checked in but A still checked out
    return 1 * multiplier;
  
  return 0; // Will never be reached
};

const oldestSortFirst = (a: Address, b: Address) => {
  return oldestSort(a, b, false);
}

const newestSortFirst = (a: Address, b: Address) => {
  return oldestSort(a, b, true);
};

const previouslyWorkedSort = (a: Address, b: Address, publisher: number, inverted: boolean) => {
  const multiplier = inverted? -1: 1;
  const indexOfA = a.records!.findIndex(r => publisher === 0 || r.publisher === publisher);
  const indexOfB = b.records!.findIndex(r => publisher === 0 || r.publisher === publisher);
  const aCheckin = indexOfA >= 0 ? a.records![indexOfA].checkin || undefined : undefined;
  const bCheckin = indexOfB >= 0 ? b.records![indexOfB].checkin || undefined : undefined;

  if (aCheckin === bCheckin) { // Checkin equal or nonexistent
    const aCheckout = indexOfA >= 0 ? a.records![indexOfA].checkout || undefined : undefined;
    const bCheckout = indexOfB >= 0 ? b.records![indexOfB].checkout || undefined : undefined;

    if (aCheckout === undefined && bCheckout === undefined)
      return addressSortFirst(a, b);
    else if (aCheckout === undefined)
      return -1 * multiplier;
    else if (bCheckout === undefined)
      return 1 * multiplier;

    const cmp = ((aCheckout > bCheckout)? 1: 0) - ((aCheckout < bCheckout)? 1: 0);
    if (cmp)
      return cmp * multiplier;

    return addressSortFirst(a, b);
  }

  if (aCheckin === undefined) // A never worked, but B must have been, since A != B
    return -1 * multiplier;
  else if (bCheckin === undefined) // B never worked, but A must have been, since A != B
    return 1 * multiplier;

  if (aCheckin && bCheckin) { // Both A and B have existing records and are both checked in
    const cmp = ((aCheckin > bCheckin)? 1: 0) - ((aCheckin < bCheckin)? 1: 0);
    if (cmp)
      return cmp * multiplier;

    return addressSortFirst(a, b);
  } else if (aCheckin) // A checked in but B still checked out
    return -1 * multiplier;
  else if (bCheckin) // B checked in but A still checked out
    return 1 * multiplier;
  
  return 0; // Will never be reached
};

const previouslyWorkedOldestSortFirst = (a: Address, b: Address, publisher: number) => {
  return previouslyWorkedSort(a, b, publisher, false);
}

const previouslyWorkedNewestSortFirst = (a: Address, b: Address, publisher: number) => {
  return previouslyWorkedSort(a, b, publisher, true);
};

interface ColumnSelectProps {
  columns: Array<Column>;
  saveColumns: (columns: Array<Column>) => void;
  dismiss: () => void;
};

const ColumnSelect: React.FC<ColumnSelectProps> = (props: ColumnSelectProps) => {
  const [columns, setColumns, columnsChanging] = BugFixes.useChangingState(props.columns);

  const onReorder = (reorder: CustomEvent<ItemReorderEventDetail>) => {
    setColumns((columns: Array<Column>) => {
      const newColumns = columns.slice();

      const direction = reorder.detail.from < reorder.detail.to ? 1 : -1;
      const itemToMove = newColumns[reorder.detail.from];

      for (var i = reorder.detail.from; i != reorder.detail.to; i += direction) {
        newColumns[i] = newColumns[i + direction];
      }

      newColumns[reorder.detail.to] = itemToMove;

      reorder.detail.complete(false);

      return newColumns;
    });
  };

  const onChangeAlignment = (columnName: string, align: any) => {
    setColumns((columns: Array<Column>) => {
      const newColumns = columns.slice();
      const column = newColumns.find(c => c.name === columnName);

      if (column)
        column.align = align;

      return newColumns;
    });
  };

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Column Ordering</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={e => props.dismiss()} >
              <IonIcon slot="icon-only" icon={closeSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      
      <IonReorderGroup disabled={false} onIonItemReorder={onReorder}>
        {columns.map((column: Column) => 
          <IonItem>
            <IonLabel>{column.name}</IonLabel>
            <IonSelect interface='popover' value={column.align} onIonChange={e => { if (!columnsChanging) onChangeAlignment(column.name, e.detail.value!) }}>
              <IonSelectOption value='default'>Default</IonSelectOption>
              <IonSelectOption value='start'>Left</IonSelectOption>
              <IonSelectOption value='end'>Right</IonSelectOption>
            </IonSelect>
            <IonReorder slot="end" />
          </IonItem>)}
      </IonReorderGroup>

      <div className="modal-button-box">
        <IonButton className="modal-button" onClick={() => setColumns(DefaultColumns())}>Revert to Default</IonButton>

        <IonButton className="modal-button" onClick={() => props.saveColumns(columns)}>Save</IonButton>
      </div>
    </IonContent>
  );
};

const SelectOptions = [
  {
    title: "Select",
    amount: -1
  },
  {
    title: "Select All",
    amount: Infinity
  },
  {
    title: "Select None",
    amount: 0
  },
  {
    title: "5",
    amount: 5
  },
  {
    title: "10",
    amount: 10
  },
  {
    title: "15",
    amount: 15
  },
  {
    title: "20",
    amount: 20
  },
  {
    title: "25",
    amount: 25
  },
  {
    title: "30",
    amount: 30
  },
  {
    title: "35",
    amount: 35
  },
  {
    title: "40",
    amount: 40
  },
  {
    title: "45",
    amount: 45
  },
  {
    title: "50",
    amount: 50
  },
  {
    title: "55",
    amount: 55
  },
  {
    title: "60",
    amount: 60
  },
  {
    title: "65",
    amount: 65
  },
  {
    title: "70",
    amount: 70
  },
  {
    title: "75",
    amount: 75
  },
  {
    title: "80",
    amount: 80
  },
  {
    title: "85",
    amount: 85
  },
  {
    title: "90",
    amount: 90
  },
  {
    title: "95",
    amount: 95
  },
  {
    title: "100",
    amount: 100
  },
  {
    title: "125",
    amount: 125
  },
  {
    title: "150",
    amount: 150
  },
];

interface AddressCardProps {
  rescrollTrigger?: (callback: (addresses: Address []) => void) => void;
  back?: string;
  selectable: boolean;
  congregationId: number;
  territoryId?: number;
  selectedAddresses?: Record<number, Address>;
  setSelectedAddresses?: (addresses: Record<number, Address>) => void;
  researchAddress?: (address: Address) => void;
  addresses: Array<Address>;
  users?: Array<User>;
  territories: Record<number, Territory>;
  congregations: Record<number, Congregation>;
  showingProfile: boolean;
};

const addressCardsSupportsColumn = (index: number, width: number): boolean => {
  return width >= index * 150;
}

const AddressCards: React.FC<AddressCardProps> = (props: AddressCardProps) => {
  const [thisPath] = React.useState(window.location.pathname.slice());
  const history = useHistory();

  const content = React.useRef<any>(null);
  const [select, setSelect] = React.useState(-1);

  const [start, setStart] = React.useState(0);
  const [limit, setLimit] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  const [columns, setColumns] = React.useState(LoadColumns());
  const [columnSelectionOpen, setColumnSelectionOpen] = React.useState(false);

  const users = props.users;
  const selectedAddresses = props.selectedAddresses;
  const addresses = props.addresses;
  const territories = props.territories;

  const setSelectAddress = (address: Address, checked: boolean) => {
    if (select >= 0) /* Bypass selection if selecting from dropdown. Not doing so causes a loop in rendering (due to the IonCheckbox IonChangeEvent handling programmatic changes) that botches everything up */
      return;

    var selected: Record<number, Address> = {...selectedAddresses};

    if (!checked) {
      delete selected[address.id];
    } else {
      selected[address.id] = address;
    }

    props.setSelectedAddresses!(selected);
  };

  useEffect(() => {
    const defaultColumns = DefaultColumns();
    const savedColumns = LoadColumns();

    var equal = savedColumns.length === defaultColumns.length;
    if (equal) {
      const columnCompare = (a: Column, b: Column) => a.name.localeCompare(b.name);

      const a = defaultColumns.slice().sort(columnCompare);
      const b = savedColumns.slice().sort(columnCompare);

      var i = a.length;
      while (i--) {
        if (a[i].name !== b[i].name) {
          equal = false;
          break;
        }
      }
    }

    if (equal)
      setColumns(savedColumns);
    else
      setColumns(defaultColumns);
  }, []);

  useEffect(() => {
    SaveColumns(columns);
  }, [columns]);

  useEffect(() => {
    if (select >= 0) {
      const array = addresses.slice(0, select === Infinity? undefined: select);
      var obj: Record<number, Address> = {};

      array.forEach(address => obj[address.id] = address);

      props.setSelectedAddresses!(obj);

      setSelect(-1);
    }
  });

  useEffect(() => {
    if (props.rescrollTrigger)
      props.rescrollTrigger(reloadListParams);

    reloadListParams(props.addresses);
  }, [props.addresses]);

  const reloadListParams = (addresses: Address []) => {
    var saved_limit = +(window.sessionStorage.getItem(thisPath + "/limit") || 150); 
    setLimit(saved_limit);

    var saved_start: any = +(window.sessionStorage.getItem(thisPath + "/start") || 0);
    setStart(Math.min(saved_start, Math.trunc(addresses.length / saved_limit) * saved_limit));
  };

  const movePage = (addresses: Address [], movement: 1 | -1, newLimit?: number) => {
    var lim = newLimit || limit;
    var strt = Math.min(Math.trunc(addresses.length / lim) * lim, Math.max(0, start + movement * lim));

    try {
      window.sessionStorage.setItem(thisPath + "/limit", String(lim));
      window.sessionStorage.setItem(thisPath + "/start", String(strt));
    } catch (e) {console.log("Cannot set session storage")};

    setStart(strt);
    setLimit(lim);
  };

  const userMap: Record<number, User> = {};
  if (users)
    users.forEach(user => userMap[user.id] = user);

  // Determine how many columns are displayed
  var columnCount = 0;
  while (addressCardsSupportsColumn(columnCount, width))
    ++columnCount;

  // Sort columns according to alignment of fields
  const actualColumns = columns
    .slice(0, columnCount)
    .sort((a, b) => {
      const ordering = ['start', 'default', 'end'];

      const aIndex = ordering.findIndex(x => x === a.align);
      const bIndex = ordering.findIndex(x => x === b.align);

      return aIndex - bIndex;
    });

  const items = addresses.slice(start, start + limit).map((address, index) => {
    const href = "/ui/address/" + encodeURIComponent(address.id) + createQuery({congregation: props.congregationId, territory: props.territoryId, back: props.back});

    const values: Record<string, any> = {
      Listing: start + index + 1,
      Territory: (territories[address.territory] || {name: ""}).name,
      Publisher: (userMap[address.records && address.records.length? address.records[0].publisher: 0] || {name: ''}).name,
      Phone: address.phone,
      Name: address.name,
      Address: address.address,
      Area: address.area,
      Demographics: address.demographic,
      Status: address.status,
      Notes: address.notes,
      Checkout: (address.records && address.records.length && SimplifyDate(address.records[0].checkout)) || '',
      Checkin: (address.records && address.records.length && SimplifyDate(address.records[0].checkin)) || '',
      'Record Notes': (address.records && address.records.length && address.records[0].notes) || '',
      'Address Verified': address.address_verified,
      'Name Verified': address.name_verified,
      Excluded: address.excluded
    };

    return (
      <IonItem disabled={false} key={address.id} href={props.selectable? undefined: href}>
        {props.selectable? <IonCheckbox slot="start" checked={selectedAddresses!.hasOwnProperty(address.id)} onClick={e => setSelectAddress(address, !selectedAddresses!.hasOwnProperty(address.id))}></IonCheckbox>: 
         null}
        {actualColumns.map((column) => {
          if (column.name === 'Listing' && props.selectable)
            return null;

           return column.name === "Research"? 
               <IonButton href="#" onClick={e => { 
                   if (props.researchAddress) 
                     props.researchAddress!(address); 
                   e.preventDefault();
                   e.stopPropagation(); 
                 }}>
                 <IonIcon slot="icon-only" icon={linkSharp} />
               </IonButton>:
               <IonLabel>
                 {values[column.name] === true || values[column.name] === false ?
                   <IonIcon icon={values[column.name] ? checkbox : squareOutline} /> :
                   values[column.name]}
               </IonLabel>;
         })}
       </IonItem>
     );
   });
 
   return (
    <>
      <ResizeObserver onResize={(size) => {
        setWidth(size.width);
      }}>

        {start != 0 || (start + limit) < addresses.length? <div className="overflow-header">
          <IonButton className="page-button" disabled={start <= 0} slot="start" onClick={e => movePage(addresses, -1)}>Previous</IonButton>
          <IonLabel>Page {Math.trunc(start / limit) + 1} of {Math.ceil(addresses.length / limit)}, {limit} results per page</IonLabel>
          <IonButton className="page-button" disabled={start + limit >= addresses.length} slot="end" onClick={e => movePage(addresses, 1)}>Next</IonButton>
        </div>: null}

        <IonList ref={content}>
          <IonItem>
            {props.selectable? 
            <IonSelect slot="start" value={select} onIonChange={e => setSelect(e.detail.value!)}>
              {SelectOptions.map(option => 
                <IonSelectOption value={option.amount}>{option.title}</IonSelectOption>
              )}
            </IonSelect>: null}

            {actualColumns.map((column, index) => {
              if (column.name === 'Listing' && props.selectable)
                return null;

              return (column.name === "Research" ? 
                <IonButton disabled={true}><IonIcon slot="icon-only" icon={linkSharp}></IonIcon></IonButton>:
                <IonLabel onClick={e => setColumnSelectionOpen(true)}>
                  <b>{column.name}</b>
                </IonLabel>
              );
            })}
          </IonItem>
          {items}
        </IonList>

        {start != 0 || (start + limit) < addresses.length? <div className="overflow-header">
          <IonButton className="page-button" disabled={start <= 0} slot="start" onClick={e => movePage(addresses, -1)}>Previous</IonButton>
          <IonLabel>Page {Math.trunc(start / limit) + 1} of {Math.ceil(addresses.length / limit)}, {limit} results per page</IonLabel>
          <IonButton className="page-button" disabled={start + limit >= addresses.length} slot="end" onClick={e => movePage(addresses, 1)}>Next</IonButton>
        </div>: null}
        
        <IonModal cssClass="column-modal" isOpen={columnSelectionOpen} onDidDismiss={() => setColumnSelectionOpen(false)}>
          <ColumnSelect columns={columns} 
                        dismiss={() => setColumnSelectionOpen(false)}
                        saveColumns={newColumns => {
                          setColumns(newColumns);
                          setColumnSelectionOpen(false);
                        }} />
        </IonModal>
      </ResizeObserver>
    </>
  );
};

interface CheckoutModalProps {
  publishers: User [];
  checkinExisting?: boolean;
  selected?: number;
  dismiss: () => void;
  submit: (publisher: number, checkinExisting: boolean) => void;
};

const CheckoutModal: React.FC<CheckoutModalProps> = (props: CheckoutModalProps) => {
  const [publisher, setPublisher] = React.useState(props.selected || 0);
  const [checkinExisting, setCheckinExisting] = React.useState(props.checkinExisting || false);

  useEffect(() => {
    setPublisher(props.selected || 0);
    setCheckinExisting(props.checkinExisting || false);
  }, [props.selected]);

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Check Out Addresses</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={e => props.dismiss()} >
              <IonIcon slot="icon-only" icon={closeSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonItem>
        <IonLabel>Publisher</IonLabel>
        <IonSelect compareWith={BugFixes.compareWithIdShim} value={{id: publisher}} onIonChange={e => setPublisher(e.detail.value!.id)}>
          {props.publishers.map(pub => 
            <IonSelectOption value={pub}>{pub.name}</IonSelectOption>
          )}
        </IonSelect>
      </IonItem>

      <IonItem>
        <IonLabel>Return publisher's current addresses</IonLabel>
        <IonCheckbox slot="start" checked={checkinExisting} onClick={e => setCheckinExisting(!checkinExisting)} />
      </IonItem>

      <div className="checkout-button-box">
        <IonButton className="checkout-button" disabled={props.publishers.find(pub => pub.id === publisher) === undefined} onClick={e => props.submit(publisher, checkinExisting)}>Check out addresses</IonButton>
      </div>
    </IonContent>
  )
};

interface BatchEditModalProps {
  congregation: Number;
  dismiss: () => void;
  submit: (address: any) => void;
};

const BatchEditModal: React.FC<BatchEditModalProps> = (props: BatchEditModalProps) => {
  const [address, setAddress] = React.useState<any>({congregation: props.congregation});

  useEffect(() => {
    setAddress(Object.assign({}, address, {congregation: props.congregation}));
  }, [props.congregation]);

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Batch Edit Addresses</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={e => props.dismiss()} >
              <IonIcon slot="icon-only" icon={closeSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <AddressFields batch={true} address={address} addressChanged={(newAddress: any) => {
        setAddress(newAddress);
      }}/>

      <div className="batch-button-box">
        <IonButton className="batch-button" disabled={Object.keys(address).length <= 1} onClick={e => props.submit(address)}>Edit addresses</IonButton>
      </div>
    </IonContent>
  )
};

const AddressFilterModes = [
  {
    title: "All: List all addresses",
    label: "Search",
    placeholder: "Search all address fields",
    id: 0
  },
  {
    title: "Check in: Search for addresses a publisher currently has",
    label: "Publisher",
    placeholder: "Publisher name",
    id: 1
  },
  {
    title: "Check out: Search for available addresses",
    placeholder: "",
    id: 2
  },
  {
    title: "Address lookup: Search for specific addresses or by area",
    label: "Address",
    placeholder: "Address or area",
    id: 3
  },
  {
    title: "Name lookup: Search for a specific householder",
    label: "Householder",
    placeholder: "Householder name",
    id: 4
  },
  {
    title: "Previously worked: Search for addresses a publisher has worked in the past",
    label: "Publisher",
    placeholder: "Publisher name",
    id: 5
  },
  {
    title: "Returned addresses: Search for returned addresses",
    label: "Search",
    placeholder: "Search all address fields",
    id: 6
  },
  {
    title: "Unknown names: Search for unknown names",
    placeholder: "",
    id: 7
  },
  {
    title: "Excluded: Search for addresses excluded from the list to be checked out",
    placeholder: "",
    id: 8
  }
];

const AddressFilterSubmodes = [
  {
    title: "Any: Letter or phone territory",
    id: 0
  },
  {
    title: "Letter writing territory",
    id: 1
  },
  {
    title: "Phone territory",
    id: 2
  },
];

const addressSubmodeMatches = (submode: number, address: Address): boolean => {
  switch (submode) {
    default: return true;
    case 1: return address.phone === '';
    case 2: return address.phone !== '';
  }
};

interface AddressFilterProps {
  rescrollTrigger?: (callback: () => void) => void;
  researchAddress?: (address: Address) => void;
  selectable: boolean;
  congregationId: number;
  territoryId: number;
  addresses: Array<Address>;
  territories: Record<number, Territory>;
  congregations: Record<number, Congregation>;
  relogin: (history: any) => void;
  addressesShown?: (addresses: Array<Address>) => void;
  requestRefresh: (displayAddressesToUser?: Record<number, Address>) => void;
  showAddresses: (displayAddressesToUser: Record<number, Address>) => void;
};

const AddressFilter: React.FC<AddressFilterProps> = (props: AddressFilterProps) => {
  enum FilterMode {
    FilterAllAddresses = 0,
    FilterCheckInCurrent = 1,
    FilterCheckOutAvailable = 2,
    FilterAddressOrArea = 3,
    FilterName = 4,
    FilterPreviouslyWorked = 5,
    FilterReturned = 6,
    FilterUnknownNames = 7
  };

  const history = useHistory();
  const [thisPath] = React.useState(window.location.pathname.slice());

  const [filteredAddresses, setFilteredAddresses] = React.useState<Address []>([]);
  const [selectedAddresses, setSelectedAddresses] = React.useState<Record<number, Address>>([]);
  const [initializing, setInitializing] = React.useState(false);
  const [runningFilter, setRunningFilter] = React.useState(false);

  const [showCheckoutModal, setShowCheckoutModal] = React.useState(false);
  const [returningUnworked, setReturningUnworked] = React.useState(false);
  const [checkingIn, setCheckingIn] = React.useState(false);
  const [checkingOut, setCheckingOut] = React.useState(false);
  const [checkinExisting, setCheckinExisting] = React.useState(false);

  const [showBatchEdit, setShowBatchEdit] = React.useState(false);
  const [batchEditing, setBatchEditing] = React.useState(false);
  const [batchData, setBatchData] = React.useState({});

  const [mode, setMode] = React.useState(0);
  const [submode, setSubmode] = React.useState(0);
  const [users, setUsers] = React.useState<User []>([]);
  const [publisher, setPublisher] = React.useState(0);
  const [text, setText] = React.useState("");

  const [message, setMessage] = React.useState("");
  
  const isPublisherMode = (mode: number) => {
    return Shared.fieldMatches(AddressFilterModes[mode].placeholder, "publisher");
  }

  const loadUsers = () => {
    axios.get(HostApiUrl() + "/api/congregation/" + encodeURIComponent(props.congregationId) + "/users", {withCredentials: true})
    .then(result => {
      setUsers(result.data.users.sort((a: User, b: User) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})));
    }).catch(err => {
      if (err.response && err.response.status === 401)
        props.relogin(history);
    });
  };

  const reloadFilterParams = () => {
    try {
      const filter = LoadFilter();

      if ((!props.congregationId || filter.congregation === props.congregationId) && (!props.territoryId || filter.territory === props.territoryId)) {
        setMode(filter.mode);
        setSubmode(filter.submode);
        setPublisher(filter.publisher);
        setText(filter.text);
      }
    } catch (e) {};
  }

  useEffect(() => {
    reloadFilterParams();

    return history.listen((location, action) => {
      if (location.pathname === thisPath)
        reloadFilterParams();
    });
  }, []);

  /* If props.addresses changes, rerun filter with Initializing set to true */
  /* If mode changes, run filter and deselect everything */
  /* If publisher or text changes, run filter and keep selection */
  useEffect(() => {
    setInitializing(true);
  }, [props.addresses]);

  useEffect(() => {
    loadUsers();

    setSelectedAddresses({});
  }, [mode]);

  useEffect(() => {
    try {
      if (props.congregationId || props.territoryId) {
        SaveFilter({congregation: props.congregationId, territory: props.territoryId, publisher: publisher, text: text, mode: mode, submode: submode});
      }
    } catch (e) {}

    setRunningFilter(true);
  }, [initializing, publisher, text, mode, submode]);

  useEffect(() => {
    if (runningFilter) {
      var newFilteredAddresses: Address[] = [];
      var usersMap: Record<number, User> = {};

      switch (mode) {
        case 0: /* all addresses */
          users.forEach(user => usersMap[user.id] = user);
          newFilteredAddresses = props.addresses.filter(address => addressSubmodeMatches(submode, address) && anyFieldMatches(props.territories, usersMap, address, text)).sort(addressSortFirst);
          break;
        case 1: /* check in */
          newFilteredAddresses = props.addresses.filter(address => addressSubmodeMatches(submode, address) && address.records && address.records.length && address.records[0].checkin === '' && (publisher === 0 || address.records[0].publisher === publisher)).sort(oldestSortFirst);
          break;
        case 2: /* check out (excluded addresses don't get included in this filter) */
          newFilteredAddresses = props.addresses.filter(address => addressSubmodeMatches(submode, address) && !address.excluded && (!address.records || address.records.length === 0 || address.records[0].checkin !== '')).sort(oldestSortFirst);
          break;
        case 3: /* address or by area */
          newFilteredAddresses = props.addresses.filter(address => addressSubmodeMatches(submode, address) && (Shared.fieldMatches(address.address, text) || Shared.fieldMatches(address.area, text))).sort(addressSortFirst);
          break;
        case 4: /* name lookup */
          newFilteredAddresses = props.addresses.filter(address => addressSubmodeMatches(submode, address) && Shared.fieldMatches(address.name, text)).sort(addressSortFirst);
          break;
        case 5: /* previously worked */
          newFilteredAddresses = props.addresses
            .filter(address => addressSubmodeMatches(submode, address) && address.records && address.records.find(record => (publisher === 0 || record.publisher === publisher) && record.checkin !== ''))
            .sort((a: Address, b:Address) => { return previouslyWorkedNewestSortFirst(a, b, publisher); });
          break;
        case 6: /* returned addresses */
          users.forEach(user => usersMap[user.id] = user);
          newFilteredAddresses = props.addresses.filter(address => addressSubmodeMatches(submode, address) && address.status !== "" && anyFieldMatches(props.territories, usersMap, address, text)).sort(newestSortFirst);
          break;
        case 7: /* unknown names */
          newFilteredAddresses = props.addresses.filter(address => addressSubmodeMatches(submode, address) && (address.name === "" || Shared.fieldMatches(address.name, "unknown") || Shared.fieldMatches(address.name, "resident"))).sort(oldestSortFirst);
          break;
        case 8: /* excluded addresses */
          newFilteredAddresses = props.addresses.filter(address => addressSubmodeMatches(submode, address) && address.excluded && anyFieldMatches(props.territories, usersMap, address, text)).sort(oldestSortFirst);
          break;
      }

      const all = [...newFilteredAddresses].sort((a, b) => a.id - b.id);
      const selected = Object.keys(selectedAddresses).map(a => Number(a)).sort((a, b) => a - b);

      var idx = 0;
      var selectIdx = 0;
      var newSelected: Record<number, Address> = {};
      for (; idx < all.length && selectIdx < selected.length; ++idx) {
        if (all[idx].id < selected[selectIdx])
          continue;

        while (selectIdx < selected.length && selected[selectIdx] < all[idx].id)
          ++selectIdx;

        if (selectIdx < selected.length && selected[selectIdx] === all[idx].id) {
          newSelected[selected[selectIdx]] = all[idx];
          ++selectIdx;
        }
      }

      setRunningFilter(false);
      setFilteredAddresses(newFilteredAddresses);
      setSelectedAddresses(newSelected);

      if (props.addressesShown)
        props.addressesShown(newFilteredAddresses);

      if (props.rescrollTrigger && initializing) {
        props.rescrollTrigger(() => {});
  
        setInitializing(false);
      }
    }
  });

  useEffect(() => {
    if (checkingIn) {
      axios.put(HostApiUrl() + "/api/records/checkin", {ids: Object.keys(selectedAddresses).map(s => Number(s)), date: undefined}, {withCredentials: true})
      .then(result => {
        setMessage("Successfully returned addresses");
        props.requestRefresh();
        setRunningFilter(true);
      }).catch(err => {
        console.log(err);
        if (err.response && err.response.status === 401)
          props.relogin(history);
        else
          setMessage("Failed to return addresses");
      });

      setCheckingIn(false);
    }
  });

  useEffect(() => {
    if (checkingOut) {
      const checkout = () => {
        axios.put(HostApiUrl() + "/api/records/checkout", {ids: Object.keys(selectedAddresses).map(s => Number(s)), publisher: publisher, date: undefined}, {withCredentials: true})
        .then(result => {
          setMessage("Successfully assigned addresses");
          props.requestRefresh(selectedAddresses);
          setRunningFilter(true);
        }).catch(err => {
          console.log(err);
          if (err.response && err.response.status === 401)
            props.relogin(history);
          else
            setMessage("Failed to assign addresses");
        });
      }

      if (checkinExisting) {
        const checkedOut = props.addresses.filter(address => address.records && address.records.length && address.records[0].checkin === '' && address.records[0].publisher === publisher);

        if (checkedOut.length) {
          axios.put(HostApiUrl() + "/api/records/checkin", {ids: checkedOut.map(address => address.id), date: undefined}, {withCredentials: true})
          .then(result => {
            checkout();
          }).catch(err => {
            console.log(err);
            if (err.response && err.response.status === 401)
              props.relogin(history);
            else
              setMessage("Failed to return existing addresses");
          });
        } else {
          checkout();
        }
      } else {
        checkout();
      }

      setCheckingOut(false);
    }
  });

  useEffect(() => {
    if (returningUnworked) {
      axios.put(HostApiUrl() + "/api/records/clear", {ids: Object.keys(selectedAddresses).map(s => Number(s))}, {withCredentials: true})
      .then(result => {
        setMessage("Successfully returned addresses unworked");
        props.requestRefresh();
        setRunningFilter(true);
      }).catch(err => {
        console.log(err);
        if (err.response && err.response.status === 401)
          props.relogin(history);
        else
          setMessage("Failed to return addresses unworked");
      });

      setReturningUnworked(false);
    }
  });

  useEffect(() => {
    if (batchEditing) {
      axios.post(HostApiUrl() + "/api/congregation/" + encodeURIComponent(props.congregationId) + "/addresses", {ids: Object.keys(selectedAddresses).map(s => Number(s)), data: batchData}, {withCredentials: true})
      .then(result => {
        setMessage("Successfully edited addresses");
        props.requestRefresh();
        setRunningFilter(true);
      }).catch(err => {
        console.log(err);
        if (err.response && err.response.status === 401)
          props.relogin(history);
        else
          setMessage("Failed to edit addresses");
      });

      setBatchEditing(false);
    }
  });

  const selectedCount = Object.keys(selectedAddresses).length;

  return (
    <>
      <IonCard>
        <IonItem>
          <IonLabel>Filter Type</IonLabel>
          <IonSelect className="mode-select" interface="alert" interfaceOptions={{cssClass: "mode-select"}} value={mode} onIonChange={e => {
              setMode(e.detail.value!); 
              if (e.detail.value! !== 2)
                setSubmode(0);
            }}>
            {AddressFilterModes.map(mode => 
              <IonSelectOption value={mode.id}>{mode.title}</IonSelectOption>
            )}
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel>Address Type</IonLabel>
          <IonSelect className="mode-select" interface="alert" interfaceOptions={{cssClass: "mode-select"}} value={submode} onIonChange={e => {setSubmode(e.detail.value!)}}>
            {AddressFilterSubmodes.map(mode => 
              <IonSelectOption value={mode.id}>{mode.title}</IonSelectOption>
            )}
          </IonSelect>
        </IonItem>
        {isPublisherMode(mode)?
        <IonItem>
          <IonLabel>Publisher</IonLabel>
          <IonSelect className="mode-select" interface="alert" interfaceOptions={{cssClass: "mode-select"}} compareWith={BugFixes.compareWithIdShim} value={{id: publisher}} onIonChange={e => setPublisher(e.detail.value.id!)}>
            <IonSelectOption value={{id: 0}}>Any User</IonSelectOption>
            {users.map(user => 
              <IonSelectOption value={user}>{user.name}</IonSelectOption>
            )}
          </IonSelect>
        </IonItem>: mode !== 7 && mode !== 2?
        <IonSearchbar placeholder={AddressFilterModes[mode].placeholder} value={text} debounce={500} onIonChange={e => setText(e.detail.value!)} />: null}
        <div className="button-box">
          {props.selectable? <IonButton disabled={selectedCount === 0} className="action-button" onClick={e => props.showAddresses(selectedAddresses)}>View selected</IonButton>: null}
          {props.selectable && mode !== 2? <IonButton 
            disabled={selectedCount === 0 || (mode !== 1 && ObjectMap(selectedAddresses).find((address: Address) => !address.records || address.records.length === 0 || address.records[0].checkin !== ''))} 
            className="action-button" 
            onClick={e => setCheckingIn(true)}>Check in</IonButton>: null}
          {props.selectable && mode !== 2? <IonButton
            disabled={selectedCount === 0 || (mode !== 1 && ObjectMap(selectedAddresses).find((address: Address) => !address.records || address.records.length === 0 || address.records[0].checkin !== ''))}
            className="action-button"
            onClick={e => setReturningUnworked(true)}>Return unworked</IonButton>: null}
          {props.selectable && mode !== 1? <IonButton 
            disabled={selectedCount === 0 || (mode !== 2 && ObjectMap(selectedAddresses).find((address: Address) => address.records && address.records.length !== 0 && address.records[0].checkin === ''))} 
            className="action-button" 
            onClick={e => setShowCheckoutModal(true)}>Check out</IonButton>: null} 
          {props.selectable? <IonButton
            disabled={selectedCount === 0}
            className="action-button"
            onClick={e => setShowBatchEdit(true)}>Batch Edit</IonButton> : null}
        </div>
      </IonCard>

      <IonModal cssClass="checkout-modal" isOpen={showCheckoutModal} onDidDismiss={e => setShowCheckoutModal(false)}>
        <CheckoutModal
            publishers={users} 
            selected={publisher}
            checkinExisting={checkinExisting}
            dismiss={() => setShowCheckoutModal(false)}
            submit={(pub, checkinExisting) => {setShowCheckoutModal(false); setPublisher(pub); setCheckinExisting(checkinExisting); setCheckingOut(true);}} />
      </IonModal>

      <IonModal cssClass="batch-modal" isOpen={showBatchEdit} onDidDismiss={e => setShowBatchEdit(false)}>
        <BatchEditModal 
         congregation={props.congregationId}
         dismiss={() => setShowBatchEdit(false)}
         submit={(address) => {setBatchData(address); setBatchEditing(true); setShowBatchEdit(false);}} />
      </IonModal>

      <AddressCards
        selectable={props.selectable}
        congregationId={props.congregationId}
        territoryId={props.territoryId}
        selectedAddresses={selectedAddresses}
        addresses={filteredAddresses}
        users={users}
        territories={props.territories}
        congregations={props.congregations}
        setSelectedAddresses={setSelectedAddresses}
        researchAddress={props.researchAddress}
        showingProfile={false}
        />

      <IonToast isOpen={message.length !== 0} onDidDismiss={() => setMessage("")} duration={ErrorMessages.DefaultDuration} message={message} />
    </>
  )
};

interface CopyModalContentProps {
  dismiss: () => void;
  selectedAddresses: Record<number, Address>;
  territories: Record<number, Territory>;
}

const CopyModalContent: React.FC<CopyModalContentProps> = (props: CopyModalContentProps) => {
  const [toastMessage, setToastMessage] = React.useState("");

  const [showAsText, setShowAsText] = React.useState(false);
  const [textContent, setTextContent] = React.useState("");
  const [htmlContent, setHtmlContent] = React.useState("");
  const [addresses, setAddresses] = React.useState<Address[]>([]);
  const [copy, setCopy] = React.useState(false);

  const HtmlContent = (props: any) => {
    return (
      <table>
        <tr>
          <th>Territory</th>
          <th>Phone</th>
          <th>Name</th>
          <th>Address</th>
          <th>Area</th>
          <th>Demographics</th>
          <th>Status</th>
          <th>Notes</th>
        </tr>
        {props.addresses.map((a: Address) => {
          return <tr>{[(props.territories[a.territory] || {name: ""}).name, a.phone, a.name, a.address, a.area, a.demographic, a.status, a.notes].map((item: any) => <td>{item}</td>)}</tr>;
        })}
      </table>
    );
  };

  useEffect(() => {
    const list = Object.keys(props.selectedAddresses).map(id => props.selectedAddresses[Number(id)]).sort(addressSortFirst);

    setTextContent(list.map((a: Address) => {
      return a.name + ": " + [a.address, a.area, a.phone, a.demographic, a.status, a.notes].filter(item => item !== "").join(' ');
    }).join("\n"));
    setHtmlContent("<body><style>th {background: rgb(207,226,243);} table, th, tr, td {border: 1px solid grey; border-collapse: collapse; margin: 0 0 0 0;}</style><table><tr><th>" + 
      ["Territory", "Phone", "Name", "Address", "Area", "Demographics", "Status", "Notes"].join("</th><th>") + "</th></tr>" + 
      list.map((a: Address) => {
        return "<tr><td>" + [(props.territories[a.territory] || {name: ""}).name, a.phone, a.name, a.address, a.area, a.demographic, a.status, a.notes].join('</td><td>') + "</td></tr>";
      }).join('') +
     "</table></body>");
    setAddresses(list);
  }, [props.selectedAddresses]);

  useEffect(() => {
    if (copy) {
      const mime = showAsText ? 'text/plain' : 'text/html';
      const blob = new Blob([showAsText ? textContent : htmlContent], { type: mime });
      const data = [new ClipboardItem({ [mime]: blob })];

      navigator.clipboard.write(data).then(
        () => {
          setToastMessage("Copied to clipboard");
        },
        () => {
          setToastMessage("Couldn't copy to clipboard");
        }
      );

      setCopy(false);
    }
  });

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Selected Addresses</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={e => props.dismiss()} >
              <IonIcon slot="icon-only" icon={closeSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="modal-button-box">
          <IonButton className="modal-button" color={showAsText? "primary": "secondary"} onClick={e => setShowAsText(false)}>Formatted for email</IonButton>
          <IonButton className="modal-button" color={showAsText? "secondary": "primary"} onClick={e => setShowAsText(true)}>Formatted for SMS</IonButton>
        </div>

        {showAsText? 
          <p className="modal-textarea">{textContent}</p>:
          <div className="modal-htmlarea"><HtmlContent territories={props.territories} addresses={addresses} /></div>}

        <div className="copy-button-box">
          <IonButton className="copy-button" onClick={() => setCopy(true)}>Copy to clipboard</IonButton>
        </div>

        <IonToast isOpen={toastMessage.length !== 0} message={toastMessage} onDidDismiss={e => setToastMessage("")} duration={ErrorMessages.DefaultDuration}></IonToast>
      </IonContent>
    </>
  );
};

interface AddressListProps {
  showFilter: boolean | undefined;
  match: any;
}

const AddressList: React.FC<AddressListProps> = (props: AddressListProps) => {
  const scrollRef = React.useRef<any>(null);

  const query = queryString.parse(window.location.search);
  const congregationId = query.congregation? Number(query.congregation): Number(props.match.params.congregationId);
  const territoryId = Number(props.match.params.territoryId);
  const thisPath = window.location.pathname.slice();

  const [loadingAddresses, setLoadingAddresses] = React.useState(false);

  const history = useHistory();

  const showFilter = props.showFilter;
  const [selecting, setSelecting] = React.useState(false);
  const [addressesToDisplayInTextForm, setAddressesToDisplayInTextForm] = React.useState<Record<number, Address>>({});

  const [congregation, setCongregation] = React.useState<number>(0);

  const [addingAddress, setAddingAddress] = React.useState(false);
  const [addresses, setAddresses] = React.useState<Address []>([]);
  const [territories, setTerritories] = React.useState<Record<number, Territory>>({});
  const [congregations, setCongregations] = React.useState<Record<number, Congregation>>({});
  const [title, setTitle] = React.useState("Addresses");
  const [addressesShown, setAddressesShown] = React.useState(0);

  const [loggedInUsers, setLoggedInUsers] = React.useState<User []>([]);
  const [showLoggedInUsers, setShowLoggedInUsers] = React.useState(false);

  const [addressToResearch, setAddressToResearch] = React.useState<Address | null>(null);

  const relogin = (history: any) => {
    history.replace("/ui/login?redirect=" + encodeURIComponent(thisPath + createQuery({congregation: congregationId || undefined, territory: territoryId || undefined})));
  };

  const loadAddressesHelper = (congregationId: number) => {
    setCongregation(congregationId);

    // First get this congregation, then get territories, then get requested addresses
    axios.get(HostApiUrl() + "/api/congregations", {withCredentials: true})
    .then(result => {
      const congregationsObj: any = {};
      result.data.congregations!.forEach((cong: any) => congregationsObj[cong.id] = cong);

      setCongregations(congregationsObj);

      axios.get(HostApiUrl() + "/api/congregation/" + encodeURIComponent(congregationId) + "/territories", {withCredentials: true})
      .then(result => {
        const territoriesObj: any = {};
        result.data.territories!.forEach((terr: any) => territoriesObj[terr.id] = terr);

        setTerritories(territoriesObj);
        setTitle((territoryId? territoriesObj[territoryId].name: congregationsObj[congregationId].name) + " Addresses");

        axios.get(HostApiUrl() + (territoryId? "/api/territory/" + encodeURIComponent(territoryId) + "/addresses": 
                                               "/api/congregation/" + encodeURIComponent(congregationId) + "/addresses"), {withCredentials: true})
        .then(result => {
          setAddresses(result.data.addresses!);
          setLoadingAddresses(false);
        }).catch(err => {
          setLoadingAddresses(false);
        });
      }).catch(err => {
        setLoadingAddresses(false);
      });
    }).catch(err => {
      console.log(err);
      setLoadingAddresses(false);

      if (err.response && err.response.status === 401)
        relogin(history);
    })
  }

  const loadAddresses = () => {
    setLoadingAddresses(true);

    if (congregationId)
      return loadAddressesHelper(congregationId);

    axios.get(HostApiUrl() + "/api/territory/" + encodeURIComponent(territoryId), {withCredentials: true})
    .then(result => {
      const territory = result.data;

      loadAddressesHelper(territory.congregation);
    }).catch(err => {
      console.log(err);
      setLoadingAddresses(false);

      if (err.response && err.response.status === 401)
        relogin(history);
    });
  };

  useEffect(() => {
    axios.get(HostApiUrl() + '/api/congregation/' + congregationId + '/logged_in')
    .then(res => {
      setLoggedInUsers(res.data.users);
    }).catch(err => {

    });

    loadAddresses();

    return history.listen((location, action) => {
      if (location.pathname === thisPath)
        loadAddresses();
    });
  }, []);

  useEffect(() => {
    if (addingAddress) {
      history.push("/ui/address/new" + createQuery({congregation: congregation || undefined, territory: territoryId || undefined}));
      setAddingAddress(false);
    }
  });

  const rescrollTrigger = (callback: (addresses: Address []) => void) => {
    setTimeout(() => { /* Retry for 10 seconds to scroll the content to match the stored value, unless the user has scrolled since then */
      const cb = (tries: number) => {
        if (tries > 100)
          return;

        if (!scrollRef.current) {
          setTimeout(cb, 100, [Number(tries) + 1]);
          return;
        }
        
        scrollRef.current.getScrollElement().then((element: any) => {
          var scrollHeight: any = window.sessionStorage.getItem(thisPath + "/scrollheight");
          var scrollTop: any = window.sessionStorage.getItem(thisPath + "/scrolltop");

          if (!scrollHeight || !scrollTop)
            return;

          scrollHeight = +scrollHeight;
          scrollTop = +scrollTop;

          if (element.scrollHeight === scrollHeight) {
            scrollRef.current.scrollToPoint(0, scrollTop);
            if (callback)
              callback(addresses);
          } else
            setTimeout(cb, 100, [Number(tries) + 1]);
        }).catch(() => {
          setTimeout(cb, 100, [Number(tries) + 1]);
        })
      };

      window.requestAnimationFrame(() => {
        cb(1);
      });
    });
  };

  const saveScrollPosition = (scroll: any) => {
    try {
      scroll.getScrollElement().then((element: any) => { 
        window.sessionStorage.setItem(thisPath + "/scrolltop", String(element.scrollTop));
        window.sessionStorage.setItem(thisPath + "/scrollheight", String(element.scrollHeight));
      });
    } catch (err) {console.log("Error saving scroll position", err)}
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {query.back? <IonBackButton defaultHref={String(query.back)} />:
            territoryId? <IonBackButton defaultHref={"/ui/congregation/" + encodeURIComponent(congregation) + "/territories"} />: 
            congregationId? <IonBackButton defaultHref="/ui/congregations" />: <IonMenuButton menu="start" />}
          </IonButtons>
          <IonTitle>{showFilter && congregation? title + " (" + addressesShown + ")": title}</IonTitle>
          <IonButtons slot="end">
            <UsersOnlineWidget users={loggedInUsers} 
              congregation={congregationId}
              onHoverEnter={() => {setShowLoggedInUsers(true)}} 
              onHoverLeave={() => {setShowLoggedInUsers(false)}} />
            <IonButton color={selecting? "secondary": "primary"} onClick={e => setSelecting(!selecting)}>Select</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen ref={scrollRef} scrollEvents={true} onIonScrollEnd={e => saveScrollPosition(e.target)}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{showFilter && congregation? title + " (" + addressesShown + ")": title}</IonTitle>
          </IonToolbar>
        </IonHeader>

        {showLoggedInUsers? 
        <IonCard className="users-online">
          {loggedInUsers.map(user => 
            <IonItem>
              <IonLabel>{UserOnlineString(user)}</IonLabel> 
            </IonItem>
          )}
        </IonCard>: null}

        {showFilter && congregation? 
          <AddressFilter
            rescrollTrigger={rescrollTrigger}
            addressesShown={(shown) => setAddressesShown(shown.length)}
            requestRefresh={(addressesToDisplay?: Record<number, Address>) => {
              if (addressesToDisplay)
                setAddressesToDisplayInTextForm(addressesToDisplay);
              loadAddresses();
            }}
            showAddresses={(addressesToDisplay: Record<number, Address>) => {
              setAddressesToDisplayInTextForm(addressesToDisplay);
            }}
            researchAddress={(address: Address) => {
              setAddressToResearch(address);
            }}
            selectable={selecting}
            congregationId={congregation} 
            territoryId={territoryId}
            relogin={relogin} 
            addresses={addresses} 
            territories={territories}
            congregations={congregations}
            />:
          <AddressCards 
            rescrollTrigger={rescrollTrigger}
            researchAddress={(address: Address) => {
              setAddressToResearch(address);
            }}
            selectable={false}
            congregationId={congregation}
            territoryId={territoryId}
            addresses={addresses}
            territories={territories}
            congregations={congregations}
            showingProfile={false} />}

        {/* Only show "Add" button if inside a territory */}
        {territoryId ? 
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={e => setAddingAddress(true)}>
              <IonIcon icon={addSharp}></IonIcon>
            </IonFabButton>
          </IonFab>: null}

        <IonLoading isOpen={loadingAddresses}></IonLoading>

        <IonModal cssClass="copy-modal" isOpen={Object.keys(addressesToDisplayInTextForm).length !== 0} onDidDismiss={e => setAddressesToDisplayInTextForm({})}>
          <CopyModalContent dismiss={() => setAddressesToDisplayInTextForm({})} territories={territories} selectedAddresses={addressesToDisplayInTextForm} />
        </IonModal>

        <IonModal cssClass="research-modal" isOpen={addressToResearch !== null} onDidDismiss={e => setAddressToResearch(null)}>
          <ExternalSiteModalContent address={addressToResearch} dismiss={() => setAddressToResearch(null)}></ExternalSiteModalContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export {
  AddressCards,
  CopyModalContent
};
export default AddressList;