import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle
} from '@ionic/react';

import React from 'react';
import { useLocation } from 'react-router-dom';
import { personOutline, personSharp, buildOutline, buildSharp, peopleOutline, peopleSharp, logOutOutline, logOutSharp } from 'ionicons/icons';
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Profile',
    url: '/ui/profile',
    iosIcon: personOutline,
    mdIcon: personSharp
  },
  {
    title: 'Congregations',
    url: '/ui/congregations',
    iosIcon: buildOutline,
    mdIcon: buildSharp
  },
  {
    title: 'Users',
    url: '/ui/users',
    iosIcon: peopleOutline,
    mdIcon: peopleSharp
  },
  {
    title: "Logout",
    url: "/ui/logout",
    iosIcon: logOutOutline,
    mdIcon: logOutSharp
  }
];

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Address Database</IonListHeader>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
