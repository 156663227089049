import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonTitle, IonToolbar } from '@ionic/react';
import { arrowForwardSharp, closeSharp } from 'ionicons/icons';
import React from 'react';
import { Address, ExternalAddressService, ObjectMap, UrlForAddress } from "../components/Global";

interface ExternalSiteModalProps {
  address?: Address | null;
  dismiss: () => void;
};

const ExternalSiteModalContent: React.FC<ExternalSiteModalProps> = (props: ExternalSiteModalProps) => {
  const services = {
    "TruePeopleSearch": ExternalAddressService.TruePeopleSearch,
    "FastPeopleSearch": ExternalAddressService.FastPeopleSearch,
    "Spokeo": ExternalAddressService.Spokeo,
    "Google Maps": ExternalAddressService.GoogleMaps,
    "Bing Maps": ExternalAddressService.BingMaps,
    "Apple Maps": ExternalAddressService.AppleMaps
  };

  const urls: Record<string, string> = {};
  ObjectMap(services, (name, service) => urls[name] = UrlForAddress(props.address, service));

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{props.address ? props.address.address : ''} Research</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={e => props.dismiss()} >
              <IonIcon slot="icon-only" icon={closeSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonList>
        {ObjectMap(urls, (name, url) => 
          <IonItem target="_blank" rel="noopener noreferrer" href={url}>
            <IonLabel>{name}</IonLabel>
            <IonIcon slot="end" icon={arrowForwardSharp} />
          </IonItem>
        )}
      </IonList>

      <iframe
        height="275"
        width="100%"
        referrerPolicy="no-referrer-when-downgrade"
        src={UrlForAddress(props.address, ExternalAddressService.GoogleMapsEmbedded)}
        allowFullScreen={true}
        />
    </IonContent>
  )
};

export type {
  ExternalSiteModalProps
}

export {
  ExternalSiteModalContent
}