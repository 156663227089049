import { 
  IonButtons, 
  IonContent, 
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonToast,
  IonLabel,
  IonAlert,
  IonItem,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonBackButton,
  IonLoading
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import ErrorMessages from '../components/ErrorMessages';
import HostApiUrl, { Territory, Congregation, MimeTypeForFileExtension } from '../components/Global';
import queryString from 'query-string';
import BugFixes from '../components/BugFixes';
import './TerritoryEdit.css';
import axios from 'axios';
import createQuery from '../components/CreateQuery';

const TerritoryEdit: React.FC = (props: any) => {
  const history = useHistory();
  const query = queryString.parse(window.location.search);
  const congregationId: number = Number(query.congregation);
  const back: string | null = congregationId? "/ui/congregation/" + encodeURIComponent(congregationId) + "/territories": null;

  const [thisPath] = React.useState(window.location.pathname.slice());

  const { id } = useParams<{ id: string; }>();
  const isNewTerritory = id.toLowerCase() == "new";

  const [rerender, setRerender] = React.useState(false);

  const [congregations, setCongregations] = React.useState<Congregation []>([]);
  const [territory, setTerritory] = React.useState<Territory>({id: isNewTerritory? 0: Number(id), name: "", notes: "", congregation: 0});

  const [loadingTerritory, setLoadingTerritory] = React.useState(true);
  const [somethingChanged, setSomethingChanged] = React.useState(false);

  const [showingDeleteModal, setShowingDeleteModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState("");

  const [congregation, setCongregation] = React.useState<number>(0);

  const fileInput: any = React.useRef(null);

  const relogin = (history: any) => {
    history.replace("/ui/login?redirect=" + encodeURIComponent("/ui/territory/" + encodeURIComponent(id) + createQuery({congregation: congregationId || undefined})));
  }

  const loadTerritory = () => {
    setLoadingTerritory(true);

    /* Get congregation and territory information from server on mount */
    axios.get(HostApiUrl() + "/api/congregations", {withCredentials: true})
    .then(result => {
      setCongregations(result.data.congregations.sort((a: Congregation, b: Congregation) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})));
    }).catch(err => {
      if (err.response && err.response.status === 401)
        relogin(history);
      else
        setError(ErrorMessages.ApiGet);
      setLoadingTerritory(false);
      setSomethingChanged(false);
    });

    if (!isNewTerritory) {
      axios.get(HostApiUrl() + "/api/territory/" + encodeURIComponent(id), {withCredentials: true})
      .then(res => {
        setTerritory(Object.assign({}, res.data));
        setCongregation(res.data.congregation);

        setTimeout(() => {
          setLoadingTerritory(false);
          setSomethingChanged(false);
        }, 0);
      }).catch(err => {
        console.log(err);
        setError(ErrorMessages.ApiGet);
        setLoadingTerritory(false);
        setSomethingChanged(false);
      });
    } else if (congregationId) {
      setCongregation(congregationId);
      setLoadingTerritory(false);
      setSomethingChanged(false);
    }
  };

  useEffect(() => {
    if (rerender)
      setRerender(false);
  }, [rerender]);

  useEffect(() => {
    loadTerritory();

    return history.listen((location, action) => {
      if (location.pathname == thisPath)
        loadTerritory();
    });
  }, []);

  useEffect(() => {
    setSomethingChanged(true);
  }, [territory, congregation]);

  useEffect(() => {
    if (submitting) {
      if (isNewTerritory) {
        const post = (data: any) => {
          axios.post(HostApiUrl() + "/api/territories", Object.assign({}, territory, {congregation: congregation, upload: data}), {withCredentials: true})
          .then(res => {
            history.replace("/ui/territory/" + encodeURIComponent(res.data.id) + createQuery({congregation: congregationId || undefined})); /* Redirect so we edit the same territory if the client edits again */
          }).catch(err => {
            if (err.response && err.response.status === 401)
              relogin(history);
            else
              setError(ErrorMessages.ApiSet);
            setSomethingChanged(true);
          });
        };

        var uploadFile: any = null;

        if (fileInput.current.files && fileInput.current.files.length) {
          uploadFile = fileInput.current.files[0];
          const uploadFileName = String(uploadFile.name);

          let reader = new FileReader();
          if (!reader) {
            setError(ErrorMessages.FileRead);
            return;
          }

          reader.readAsBinaryString(uploadFile);

          reader.onloadend = () => {post({data: btoa(reader.result as string), mime: MimeTypeForFileExtension(uploadFileName.substring(uploadFileName.lastIndexOf('.')+1))})};
          reader.onerror = () => {setError(ErrorMessages.FileRead)};
        } else {
          post(undefined);
        }
      } else { /* Editing a currently existing territory */
        axios.put(HostApiUrl() + "/api/territory/" + encodeURIComponent(id), Object.assign({}, territory, {congregation: congregation}), {withCredentials: true})
        .then(res => {
          setTerritory(res.data);

          setTimeout(() => {
            setSomethingChanged(false);
          }, 0);
        }).catch(err => {
          if (err.response && err.response.status === 401)
            relogin(history);
          else
            setError(ErrorMessages.ApiSet);
          setSomethingChanged(true);
        });
      }

      setSomethingChanged(false);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (deleting) {
      axios.delete(HostApiUrl() + "/api/territory/" + encodeURIComponent(id), {withCredentials: true})
      .then(res => {
        if (back)
          history.replace(back);
      }).catch(err => {
        if (err.response && err.response.status === 401)
          relogin(history);
        else
          setError(ErrorMessages.ApiDelete);
      });

      setDeleting(false);
    }
  }, [deleting]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {back? <IonBackButton defaultHref={back} />: <IonMenuButton />}
          </IonButtons>
          <IonTitle>{isNewTerritory? "New ": null}Territory {territory.name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonAlert 
          isOpen={showingDeleteModal} 
          onDidDismiss={e => setShowingDeleteModal(false)} 
          cssClass="delete-modal" 
          header="Confirm Deletion"
          message={"Are you sure you want to delete " + (territory.name || "this territory") + "?"}
          buttons={[
            {
              text: 'Keep this territory',
              role: 'cancel',
              handler: () => {setShowingDeleteModal(false);}
            },
            {
              text: 'Delete',
              handler: () => {setDeleting(true); setShowingDeleteModal(false);}
            }]}
          />
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{isNewTerritory? "New ": null}Territory {territory.name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard className="user">
          <IonItem>
            <IonLabel>Territory Name</IonLabel>
            <IonInput className="user-input" type="text" placeholder="Territory Name" value={territory.name} onIonChange={e => setTerritory(Object.assign({}, territory, {name: String(e.detail.value!)}))} />
          </IonItem>
          <IonItem>
            <IonLabel>Notes</IonLabel>
            <IonInput className="user-input" type="text" placeholder="Territory Notes" value={territory.notes} onIonChange={e => setTerritory(Object.assign({}, territory, {notes: String(e.detail.value!)}))} />
          </IonItem>
          <IonItem>
            <IonLabel>Congregation</IonLabel>
            <IonSelect slot="end" compareWith={BugFixes.compareWithIdShim} value={{id: congregation}} onIonChange={e => setCongregation(Number(e.detail.value.id!))}>
              {congregations.map(cong => {
                return (<IonSelectOption value={cong}>{cong.name}</IonSelectOption>)
              })}
            </IonSelect>
          </IonItem>
          {isNewTerritory?
          <IonItem>
            <IonLabel>Upload Addresses</IonLabel>
            <input className="actual-file-input" id="fileBrowse" type="file" ref={fileInput} onChange={e => setRerender(true)} />
            <IonLabel>{fileInput.current && fileInput.current.files && fileInput.current.files[0]? fileInput.current.files[0].name: null}</IonLabel>
            <IonButton onClick={e => document.getElementById("fileBrowse")?.click()}>Browse...</IonButton>
          </IonItem>: null}
          <div className="button-box">
            <IonButton disabled={isNewTerritory || submitting || deleting || showingDeleteModal} className="action-button" onClick={e => setShowingDeleteModal(true)}>Delete</IonButton>
            <IonButton disabled={!somethingChanged || submitting || deleting || showingDeleteModal} className="action-button" onClick={e => setSubmitting(true)}>Save</IonButton>
          </div>
        </IonCard>

        <IonLoading isOpen={loadingTerritory}></IonLoading>

        <IonToast isOpen={error.length !== 0} onDidDismiss={() => setError("")} duration={ErrorMessages.DefaultDuration} message={error} />
      </IonContent>
    </IonPage>
  );
};

export default TerritoryEdit;
