import { 
  IonButtons, 
  IonContent, 
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonLabel,
  IonAlert,
  IonToast,
  IonItem,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonBackButton,
  IonLoading
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import HostApiUrl, { User, Congregation } from '../components/Global';
import queryString from 'query-string';
import BugFixes from '../components/BugFixes';
import ErrorMessages from '../components/ErrorMessages';
import './UserEdit.css';
import axios from 'axios';
import createQuery from '../components/CreateQuery';
import { Roles, RolesFromArray, RolesToArray } from '../components/Users';

const UserEdit: React.FC = (props: any) => {
  const history = useHistory();
  const query = queryString.parse(window.location.search);
  const congregationId: number = Number(query.congregation);
  const fromProfile: number = Number(query.profile);
  const back: string = query.back? String(query.back): fromProfile? "/ui/profile": congregationId? "/ui/congregation/" + encodeURIComponent(congregationId) + "/users": "/ui/users";

  const [thisPath] = React.useState(window.location.pathname.slice());

  const { id } = useParams<{ id: string; }>();
  const isNewUser = id.toLowerCase() == "new";

  const [congregations, setCongregations] = React.useState<Congregation []>([]);
  const [user, setUser] = React.useState<User>({id: isNewUser? 0: Number(id), name: "", username: "", email: "", congregation: 0, roles: 0});

  const [showingDeleteModal, setShowingDeleteModal] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState("");

  const [loadingUser, setLoadingUser] = React.useState(true);
  const [somethingChanged, setSomethingChanged] = React.useState(false);
  
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [password, setPassword] = React.useState<string>();
  const [congregation, setCongregation] = React.useState<number>(0);
  const [roles, setRoles] = React.useState<Array<number>>([]);

  const relogin = (history: any) => {
    history.replace("/ui/login?redirect=" + encodeURIComponent("/ui/user/" + encodeURIComponent(id) + createQuery({profile: fromProfile || undefined, congregation: congregationId || undefined})));
  }

  const loadUser = () => {
    setLoadingUser(true);

    /* Get congregation and user information from server on mount */
    axios.get(HostApiUrl() + "/api/congregations", {withCredentials: true})
    .then(result => {
      setCongregations(result.data.congregations.sort((a: Congregation, b: Congregation) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'})));
    }).catch(err => {
      if (err.response && err.response.status === 401)
        relogin(history);
      else
        setError(ErrorMessages.ApiGet);
      setLoadingUser(false);
      setSomethingChanged(false);
    });

    if (!isNewUser) {
      axios.get(HostApiUrl() + "/api/user/" + encodeURIComponent(id), {withCredentials: true})
      .then(res => {
        setUser(Object.assign({}, res.data));
        setCongregation(res.data.congregation);
        setRoles(RolesToArray(res.data.roles));

        setTimeout(() => {
          setLoadingUser(false);
          setSomethingChanged(false);
        }, 0);
      }).catch(err => {
        console.log(err);
        setError(ErrorMessages.ApiGet);
        setLoadingUser(false);
        setSomethingChanged(false);
      });
    } else {
      if (congregationId) {
        setCongregation(Number(congregationId));
      }
      
      setLoadingUser(false);
      setSomethingChanged(false);
    }
  };

  useEffect(() => {
    loadUser();

    return history.listen((location, action) => {
      if (location.pathname === thisPath)
        loadUser();
    });
  }, []);

  useEffect(() => {
    setSomethingChanged(true);
  }, [user, password, congregation, roles]);

  useEffect(() => {
    if (submitting) {
      if (isNewUser) {
        axios.post(HostApiUrl() + "/api/users", Object.assign({}, user, {congregation: congregation, roles: RolesFromArray(roles)}, passwordChanged? {password: password}: {}), {withCredentials: true})
        .then(res => {
          history.replace("/ui/user/" + encodeURIComponent(res.data.id) + createQuery({profile: fromProfile || undefined, congregation: congregationId || undefined})); /* Redirect so we edit the same user if the client edits again */
        }).catch(err => {
          if (err.response && err.response.status === 401)
            relogin(history);
          else
            setError(ErrorMessages.ApiSet);
          setSomethingChanged(true);
        });
      } else { /* Editing a currently existing user */
        axios.put(HostApiUrl() + "/api/user/" + encodeURIComponent(id), Object.assign({}, user, {congregation: congregation, roles: RolesFromArray(roles)}, passwordChanged? {password: password}: {}), {withCredentials: true})
        .then(res => {
          setUser(res.data);
          setRoles(RolesToArray(res.data.roles));

          setTimeout(() => {
            setSomethingChanged(false);
          })
        }).catch(err => {
          if (err.response && err.response.status === 401)
            relogin(history);
          else
            setError(ErrorMessages.ApiSet);
          setSomethingChanged(true);
        });
      }

      setSomethingChanged(false);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (deleting) {
      axios.delete(HostApiUrl() + "/api/user/" + encodeURIComponent(id), {withCredentials: true})
      .then(res => {
        history.replace(back);
      }).catch(err => {
        if (err.response && err.response.status === 401)
          relogin(history);
        else
          setError(ErrorMessages.ApiDelete);
      });

      setDeleting(false);
    }
  }, [deleting]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={back} />
          </IonButtons>
          <IonTitle>{isNewUser? "New ": null}User {user.name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonAlert 
          isOpen={showingDeleteModal} 
          onDidDismiss={e => setShowingDeleteModal(false)} 
          cssClass="delete-modal" 
          header="Confirm Deletion"
          message={"Are you sure you want to delete " + (user.name || "this user") + "?"}
          buttons={[
            {
              text: 'Keep this user',
              role: 'cancel',
              handler: () => {setShowingDeleteModal(false);}
            },
            {
              text: 'Delete',
              handler: () => {setDeleting(true); setShowingDeleteModal(false);}
            }]}
          />
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{isNewUser? "New ": null}User {user.name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonCard className="user">
          <IonItem>
            <IonLabel>Full Name</IonLabel>
            <IonInput className="user-input" type="text" placeholder="Full Name" value={user.name} onIonChange={e => setUser(Object.assign({}, user, {name: String(e.detail.value!)}))} />
          </IonItem>
          <IonItem>
            <IonLabel>Username</IonLabel>
            <IonInput className="user-input" type="text" placeholder="Username" value={user.username} onIonChange={e => setUser(Object.assign({}, user, {username: String(e.detail.value!)}))} />
          </IonItem>
          <IonItem>
            <IonLabel>Password</IonLabel>
            <IonInput className="user-input" type="password" placeholder="User's Password" value={password} onIonChange={e => {setPassword(e.detail.value!); setPasswordChanged(true);}} />
          </IonItem>
          <IonItem>
            <IonLabel>Email</IonLabel>
            <IonInput className="user-input" type="email" placeholder="Email" value={user.email} onIonChange={e => setUser(Object.assign({}, user, {email: String(e.detail.value!)}))} />
          </IonItem>
          <IonItem>
            <IonLabel>Congregation</IonLabel>
            <IonSelect slot="end" compareWith={BugFixes.compareWithIdShim} value={{id: congregation}} onIonChange={e => setCongregation(Number(e.detail.value.id!))}>
              {congregations.map(cong => {
                return (<IonSelectOption value={cong}>{cong.name}</IonSelectOption>)
              })}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>Access Rights</IonLabel>
            <IonSelect slot="end" multiple value={roles} onIonChange={e => setRoles(e.detail.value!)}>
              {Roles.map(role => {
                return (<IonSelectOption value={role.value}>{role.title}</IonSelectOption>)
              })}
            </IonSelect>
          </IonItem>
          <div className="button-box">
            <IonButton disabled={isNewUser || submitting || deleting || showingDeleteModal} className="action-button" onClick={e => setShowingDeleteModal(true)}>Delete</IonButton>
            <IonButton disabled={!somethingChanged || submitting || deleting || showingDeleteModal} className="action-button" onClick={e => setSubmitting(true)}>Save</IonButton>
          </div>
        </IonCard>

        <IonLoading isOpen={loadingUser}></IonLoading>

        <IonToast isOpen={error.length !== 0} onDidDismiss={() => setError("")} duration={ErrorMessages.DefaultDuration} message={error} />
      </IonContent>
    </IonPage>
  );
};

export default UserEdit;
