import React, { useEffect } from 'react';

const compareWithIdShim = (curr: any, compar: any) => {return curr && compar? curr.id === compar.id: curr === compar};

const useChangingState = (initialState: any) => {
  const [unsafeState, setUnsafeState] = React.useState(initialState);
  const [pendingState, setPendingState] = React.useState(initialState);
  const [stateIsPending, setStateIsPending] = React.useState(false);

  useEffect(() => {
    setStateIsPending(true);
  }, [pendingState]);

  useEffect(() => {
    if (stateIsPending) {
      setUnsafeState(pendingState);
      setStateIsPending(false);
    }
  }, [pendingState, stateIsPending]);

  return [ unsafeState, setPendingState, stateIsPending ];
};

const BugFixes = {
  compareWithIdShim,
  useChangingState
};

export default BugFixes;