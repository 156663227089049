import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCardTitle,
  IonContent,
  IonCard,
  IonItem,
  IonLoading,
  IonLabel,
  IonIcon,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonModal
} from '@ionic/react';
import React, { useEffect } from 'react';
import { settingsSharp } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import HostApiUrl, { Congregation, Territory, Address, User } from '../components/Global';
import { LoadUser, SaveUser } from '../components/Storage';
import { AddressCards } from '../pages/AddressList';
import './Profile.css';
import axios from 'axios';
import { ExternalSiteModalContent } from './AddressCommon';

const Profile: React.FC = (props: any) => {
  const history = useHistory();

  const [user, setUser] = React.useState<User>({name: "", id: 0, username: "", congregation: 0, email: "", roles: 0})
  const [congregations, setCongregations] = React.useState<Record<number, Congregation>>({});
  const [territories, setTerritories] = React.useState<Record<number, Territory>>({});
  const [addresses, setAddresses] = React.useState<Address []>([]);

  const [addressToResearch, setAddressToResearch] = React.useState<Address | null>(null);

  const relogin = (history: any) => {
    history.replace("/ui/login?redirect=" + encodeURIComponent("/ui/profile"));
  }

  useEffect(() => {
    const load = (publisherId: number, congregationId: number) => {
      axios.get(HostApiUrl() + "/api/user/" + encodeURIComponent(publisherId) + "/addresses", {withCredentials: true})
      .then(result => {
        setAddresses(result.data.addresses!);
      }).catch(err => {
        console.log(err);
        if (err.response && err.response.status === 401)
          relogin(history);
      });

      axios.get(HostApiUrl() + "/api/user/" + encodeURIComponent(publisherId), {withCredentials: true})
      .then(result => {
        setUser(result.data);
      }).catch(err => {
        console.log(err);
      });

      axios.get(HostApiUrl() + "/api/congregations", {withCredentials: true})
      .then(result => {
        const congregationsObj: any = {};
        result.data.congregations!.forEach((cong: any) => congregationsObj[cong.id] = cong);

        setCongregations(congregationsObj);
      }).catch(err => {
        console.log(err);
      });

      axios.get(HostApiUrl() + "/api/congregation/" + encodeURIComponent(congregationId) + "/territories", {withCredentials: true})
      .then(result => {
        const territoriesObj: any = {};
        result.data.territories!.forEach((terr: any) => territoriesObj[terr.id] = terr);

        setTerritories(territoriesObj); 
      }).catch(err => {
        console.log(err);
      });
    }

    const user = LoadUser();

    if (user.id === 0) {
      axios.get(HostApiUrl() + "/api/session", {withCredentials: true})
      .then(result => {
        SaveUser(result.data);
        load(result.data.user!, result.data.congregation!);
      }).catch(err => {
        console.log("User not available");
        relogin(history);
      });
    } else {
      load(user.id, user.congregation);
    }
  }, []);

  if (user.id === 0) {
    return (<IonPage><IonContent fullscreen><IonLoading isOpen={true} /></IonContent></IonPage>);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>My Profile</IonTitle>

          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonButtons slot="end">
            <IonButton href={"/ui/user/" + encodeURIComponent(user.id) + "?profile=1"}>
              <IonIcon slot="icon-only" icon={settingsSharp}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">My Profile</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonCard>
          <IonCardTitle class="profile-header">Congregation</IonCardTitle>

          <IonItem>
            <IonLabel slot="start"><b>Name</b></IonLabel>
            <IonLabel>{(congregations[user.congregation] || {name: ""}).name}</IonLabel>
          </IonItem>
        </IonCard>

        <IonCard>
          <IonCardTitle class="profile-header">My Addresses</IonCardTitle>

          <AddressCards 
            selectable={false}
            back={"/ui/profile"}
            researchAddress={(address: Address) => {
              setAddressToResearch(address);
            }}
            congregationId={user.congregation}
            addresses={addresses}
            territories={territories}
            congregations={congregations}
            users={[user]}
            showingProfile={true}
           />
        </IonCard>

        <IonModal cssClass="research-modal" isOpen={addressToResearch !== null} onDidDismiss={e => setAddressToResearch(null)}>
          <ExternalSiteModalContent address={addressToResearch} dismiss={() => setAddressToResearch(null)}></ExternalSiteModalContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Profile;