import Menu from './components/Menu';
import LoginChecker from './pages/LoginChecker';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Profile from './pages/Profile';
import UserEdit from './pages/UserEdit';
import UserList from './pages/UserList';
import CongregationEdit from './pages/CongregationEdit';
import CongregationList from './pages/CongregationList';
import TerritoryEdit from './pages/TerritoryEdit';
import TerritoryList from './pages/TerritoryList';
import AddressEdit from './pages/AddressEdit';
import AddressList from './pages/AddressList';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Global settings for modals */
import './App.css';

const shouldHideMenuForPath = (path: string) => {
  return path === "/ui/login" || path === "/ui/logout";
}

interface AppRouterProps {
  setHideMenu: (yes: boolean) => void;
}

const AppRouter: React.FC<AppRouterProps> = (props: AppRouterProps) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      props.setHideMenu(shouldHideMenuForPath(location.pathname));
    });

    return unlisten;
  }, [history, props]);

  return (
    <IonRouterOutlet id="main">
      <Route path="/" component={LoginChecker} exact />
      <Route path="/ui/profile" component={Profile} exact />
      <Route path="/ui/login" component={Login} exact />
      <Route path="/ui/logout" component={Logout} exact />
      <Route path="/ui/users" component={UserList} exact />
      <Route path="/ui/user/:id" component={UserEdit} exact />
      <Route path="/ui/territories" component={TerritoryList} exact />
      <Route path="/ui/territory/:id" component={TerritoryEdit} exact />
      <Route path="/ui/territories" component={TerritoryList} exact />
      <Route path="/ui/territory/:id" component={TerritoryEdit} exact />
      <Route path="/ui/territory/:territoryId/addresses" render={(props) => {return (<AddressList showFilter={true} {...props} />);}} exact />
      <Route path="/ui/congregations" component={CongregationList} exact />
      <Route path="/ui/congregation/:id" component={CongregationEdit} exact />
      <Route path="/ui/congregation/:congregationId/users" component={UserList} exact />
      <Route path="/ui/congregation/:congregationId/territories" component={TerritoryList} exact />
      <Route path="/ui/congregation/:congregationId/addresses" render={(props) => {return (<AddressList showFilter={true} {...props} />);}} exact />
      <Route path="/ui/address/:id" component={AddressEdit} exact />
    </IonRouterOutlet>
  );
};

const App: React.FC = (props: any) => {
  const [hideMenu, setHideMenu] = React.useState(shouldHideMenuForPath(window.location.pathname));

  return (
    <IonApp>
      <IonReactRouter>
        {hideMenu? <AppRouter setHideMenu={setHideMenu} />:
        <IonSplitPane className="split-pane" contentId="main" when="(min-width: 1920px)">
          <Menu />
          <AppRouter setHideMenu={setHideMenu} />
        </IonSplitPane>}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
