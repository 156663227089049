const createQuery = (queries: any) => {
  var string = "";

  var count = 0;
  for (var key in queries) {
    if (queries.hasOwnProperty(key)) {
      const val = queries[key];

      if (val !== undefined && (typeof val !== 'number' || !isNaN(val))) {
        if (count++)
          string += "&";
  
        string += encodeURIComponent(key) + "=" + encodeURIComponent(val);
      }
    }
  }

  return string.length? "?" + string: "";
};

export default createQuery;