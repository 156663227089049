const RoleReadPersonal = 0x001;
const RoleReadCongregation = 0x002;
const RoleReadAll = 0x004;
const RoleWritePersonal = 0x008;
const RoleWriteCongregation = 0x010;
const RoleWriteAll = 0x020;
const RoleAdmin = 0x040;

const Roles = [
  {
    title: "Read personal",
    value: RoleReadPersonal
  },
  {
    title: "Read congregation",
    value: RoleReadCongregation
  },
  {
    title: "Read all",
    value: RoleReadAll
  },
  {
    title: "Edit personal",
    value: RoleWritePersonal
  },
  {
    title: "Edit congregation",
    value: RoleWriteCongregation
  },
  {
    title: "Edit all",
    value: RoleWriteAll
  },
  {
    title: "Administrator",
    value: RoleAdmin
  }
];

const RolesToArray = (roles: number) => {
	var array = [];

	while (roles) {
		array.push(roles & ~(roles - 1));
		roles &= roles - 1;
	}

	return array;
}

const NameForRole = (role: number) => {
	return (Roles.find(value => value.value === role) || {title: ''}).title;
}

const RolesFromArray = (rolesArray: Array<number>) => {
	const value = rolesArray.length? rolesArray.reduce((previous, current) => { return previous | current }) : 0;

	return value;
}

export {
	RoleReadPersonal,
	RoleReadCongregation,
	RoleReadAll,
	RoleWritePersonal,
	RoleWriteCongregation,
	RoleWriteAll,
	RoleAdmin,
  Roles,
	RolesToArray,
	NameForRole,
	RolesFromArray
}