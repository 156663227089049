import { IonIcon, IonLabel } from '@ionic/react';
import React from 'react';
import { User } from './Global';
import { checkmarkCircle, alertCircle } from 'ionicons/icons';

interface UsersOnlineWidgetProps {
  users: User [];
  congregation: number;

  onHoverEnter: () => void;
  onHoverLeave: () => void;
}

const UserOnlineString = (user: User) => {
    if (!user.last_seen)
        return user.name;
    
    const minutes_ago = Math.floor(((new Date()).getTime() - (new Date(user.last_seen)).getTime()) / 60000);

    return user.name + ' ' + (minutes_ago === 1 ? "(last seen 1 minute ago)" : `(last seen ${minutes_ago} minutes ago)`);
};

const UsersOnlineWidget: React.FC<UsersOnlineWidgetProps> = (props: UsersOnlineWidgetProps) => {
    if (!props.congregation)
        return <></>;

    return props.users.length === 1 ?
        <IonLabel onMouseEnter={() => props.onHoverEnter()} onMouseLeave={() => props.onHoverLeave()}>
            <IonIcon color='success' icon={checkmarkCircle} /> 1 User Online
        </IonLabel>:
        <IonLabel onMouseEnter={() => props.onHoverEnter()} onMouseLeave={() => props.onHoverLeave()}>
            <IonIcon color='warning' icon={alertCircle} /> {props.users.length} Users Online
        </IonLabel>
    ;
};

export {
    UserOnlineString,
    UsersOnlineWidget
}

export default UsersOnlineWidget;