const roadmap = {
  'alley': 'alley',
  'allee': 'alley',
  'ally': 'alley',
  'aly': 'alley',

  'annex': 'annex',
  'anex': 'annex',
  'annx': 'annex',

  'arcade': 'arcade',
  'arc': 'arcade',

  'avenue': 'avenue',
  'avenu': 'avenue',
  'aven': 'avenue',
  'ave': 'avenue',
  'av': 'avenue',
  'avn': 'avenue',
  'avnue': 'avenue',

  'bayou': 'bayou',
  'bayoo': 'bayou',
  'byu': 'bayou',

  'beach': 'beach',
  'bch': 'beach',

  'bend': 'bend',
  'bnd': 'bend',

  'bluff': 'bluff',
  'bluf': 'bluff',
  'blf': 'bluff',

  'bluffs': 'bluffs',
  'blfs': 'bluffs',

  'bottom': 'bottom',
  'bot': 'bottom',
  'bottm': 'bottom',
  'btm': 'bottom',

  'boulevard': 'boulevard',
  'boul': 'boulevard',
  'blvd': 'boulevard',
  'boulv': 'boulevard',

  'branch': 'branch',
  'brnch': 'branch',
  'br': 'branch',

  'bridge': 'bridge',
  'brdge': 'bridge',
  'brdg': 'bridge',
  'brg': 'bridge',

  'brook': 'brook',
  'brk': 'brook',

  'brooks': 'brooks',
  'brks': 'brooks',

  'burg': 'burg',
  'bg': 'burg',

  'burgs': 'burgs',
  'bgs': 'burgs',

  'bypass': 'bypass',
  'bypas': 'bypass',
  'bypa': 'bypass',
  'byps': 'bypass',
  'byp': 'bypass',

  'camp': 'camp',
  'cmp': 'camp',
  'cp': 'camp',

  'canyon': 'canyon',
  'canyn': 'canyon',
  'cnyn': 'canyon',
  'cyn': 'canyon',

  'cape': 'cape',
  'cpe': 'cape',

  'causeway': 'causeway',
  'causwa': 'causeway',
  'cswy': 'causeway',

  'center': 'center',
  'centre': 'center',
  'centr': 'center',
  'cnter': 'center',
  'cent': 'center',
  'cntr': 'center',
  'cen': 'center',
  'ctr': 'center',
  
  'centers': 'centers',
  'ctrs': 'centers',

  'circle': 'circle',
  'circl': 'circle',
  'crcle': 'circle',
  'crcl': 'circle',
  'circ': 'circle',
  'cir': 'circle',

  'circles': 'circles',
  'cirs': 'circles',

  'cliff': 'cliff',
  'clf': 'cliff',

  'cliffs': 'cliffs',
  'clfs': 'cliffs',

  'club': 'club',
  'clb': 'club',

  'common': 'common',
  'cmn': 'common',

  'commons': 'commons',
  'cmns': 'commons',

  'corner': 'corner',
  'cor': 'corner',

  'corners': 'corners',
  'cors': 'corners',

  'course': 'course',
  'crse': 'course',

  'court': 'court',
  'crt': 'court',
  'ct': 'court',

  'courts': 'courts',
  'crts': 'courts',
  'cts': 'courts',

  'cove': 'cove',
  'cv': 'cove',

  'coves': 'coves',
  'cvs': 'coves',

  'creek': 'creek',
  'crk': 'creek',

  'crescent': 'crescent',
  'crsent': 'crescent',
  'crsnt': 'crescent',
  'cres': 'crescent',

  'crest': 'crest',
  'crst': 'crest',

  'crossing': 'crossing',
  'crssng': 'crossing',
  'xing': 'crossing',

  'crossroad': 'crossroad',
  'xrd': 'crossroad',

  'curve': 'curve',
  'curv': 'curve',

  'dale': 'dale',
  'dl': 'dale',
  
  'dam': 'dam',
  'dm': 'dam',

  'divide': 'divide',
  'div': 'divide',
  'dvd': 'divide',
  'dv': 'divide',

  'drive': 'drive',
  'driv': 'drive',
  'drv': 'drive',
  'dr': 'drive',

  'drives': 'drives',
  'drs': 'drives',

  'estate': 'estate',
  'est': 'estate',

  'expressway': 'expressway',
  'express': 'expressway',
  'expr': 'expressway',
  'expw': 'expressway',
  'expy': 'expressway',
  'exp': 'expressway',

  'extension': 'extension',
  'extnsn': 'extension',
  'extn': 'extension',
  'ext': 'extension',

  'extensions': 'extensions',
  'exts': 'extensions',

  'fall': 'fall',

  'falls': 'falls',
  'fls': 'falls',

  'ferry': 'ferry',
  'frry': 'ferry',
  'fry': 'ferry',

  'field': 'field',
  'fld': 'field',
  
  'fields': 'fields',
  'flds': 'fields',

  'flat': 'flat',
  'flt': 'flat',

  'flats': 'flats',
  'flts': 'flats',

  'ford': 'ford',
  'frd': 'ford',

  'fords': 'fords',
  'frds': 'fords',

  'forest': 'forest',
  'frst': 'forest',

  'forge': 'forge',
  'forg': 'forge',
  'frg': 'forge',
  
  'forges': 'forges',
  'frgs': 'frgs',

  'fork': 'fork',
  'frk': 'fork',

  'forks': 'forks',
  'frks': 'forks',

  'fort': 'fort',
  'frt': 'fort',
  'ft': 'fort',

  'freeway': 'freeway',
  'freewy': 'freeway',
  'frway': 'freeway',
  'frwy': 'freeway',
  'fwy': 'freeway',

  'garden': 'garden',
  'gardn': 'garden',
  'grden': 'garden',
  'grdn': 'garden',
  'gdn': 'garden',

  'gardens': 'gardens',
  'gdns': 'gardens',

  'gateway': 'gateway',
  'gatewy': 'gateway',
  'gatway': 'gateway',
  'gtway': 'gateway',
  'gtwy': 'gateway',

  'glen': 'glen',
  'gln': 'glen',

  'glens': 'glens',
  'glns': 'glens',

  'green': 'green',
  'grn': 'green',

  'greens': 'greens',
  'grns': 'greens',

  'grove': 'grove',
  'grov': 'grove',
  'grv': 'grove',

  'groves': 'groves',
  'grvs': 'groves',
  
  'harbor': 'harbor',
  'harbr': 'harbor',
  'hrbor': 'harbor',
  'harb': 'harbor',
  'hbr': 'harbor',

  'harbors': 'harbors',
  'hbrs': 'harbors',
  
  'haven': 'haven',
  'hvn': 'haven',

  'heights': 'heights',
  'hghts': 'heights',
  'hts': 'heights',

  'highway': 'highway',
  'highwy': 'highway',
  'hiway': 'highway',
  'hiwy': 'highway',
  'hway': 'highway',
  'hwy': 'highway',

  'hill': 'hill',
  'hl': 'hill',

  'hills': 'hills',
  'hls': 'hills',

  'hollow': 'hollow',
  'hllw': 'hollow',
  'holws': 'hollow',
  'holw': 'hollow',

  'inlet': 'inlet',
  'inlt': 'inlet',
  
  'island': 'island',
  'is': 'island',

  'islands': 'islands',
  'iss': 'islands',

  'isle': 'isle',

  'junction': 'junction',
  'juncton': 'junction',
  'junctn': 'junction',
  'jction': 'junction',
  'jctn': 'junction',
  'jct': 'junction',

  'junctions': 'junctions',
  'jcts': 'junctions',

  'key': 'key',
  'ky': 'key',

  'keys': 'keys',
  'kys': 'keys',

  'knoll': 'knoll',
  'knol': 'knoll',
  'knl': 'knoll',

  'knolls': 'knolls',
  'knls': 'knolls',

  'lake': 'lake',
  'lk': 'lake',

  'lakes': 'lakes',
  'lks': 'lakes',

  'land': 'land',

  'landing': 'landing',
  'lndng': 'landing',
  'lndg': 'landing',

  'lane': 'lane',
  'ln': 'lane',

  'light': 'light',
  'lgt': 'light',

  'lights': 'lights',
  'lgts': 'lights',

  'loaf': 'loaf',
  'lf': 'loaf',

  'lock': 'lock',
  'lck': 'lock',

  'locks': 'locks',
  'lcks': 'locks',

  'lodge': 'lodge',
  'ldge': 'lodge',
  'lodg': 'lodge',
  'ldg': 'lodge',

  'loop': 'loop',

  'mall': 'mall',

  'manor': 'manor',
  'mnr': 'manor',

  'manors': 'manors',
  'mnrs': 'manors',

  'meadow': 'meadow',
  'mdw': 'meadow',

  'meadows': 'meadows',
  'medows': 'meadows',
  'mdws': 'meadows',

  'mews': 'mews',

  'mill': 'mill',
  'ml': 'mill',

  'mills': 'mills',
  'mls': 'mills',

  'mission': 'mission',
  'msn': 'mission',

  'motorway': 'motorway',
  'mtwy': 'motorway',

  'mount': 'mount',
  'mt': 'mount',

  'mountain': 'mountain',
  'mtn': 'mountain',

  'mountains': 'mountains',
  'mtns': 'mountains',

  'neck': 'neck',

  'orchard': 'orchard',
  'orchrd': 'orchard',
  'orch': 'orchard',

  'oval': 'oval',
  'ovl': 'oval',

  'overpass': 'overpass',
  'opas': 'overpass',

  'park': 'park',
  'prk': 'park',

  'parks': 'parks',
  'prks': 'parks',

  'parkway': 'parkway',
  'parkwy': 'parkway',
  'pkway': 'parkway',
  'pkwy': 'parkway',
  'pky': 'parkway',

  'parkways': 'parkways',
  'pkwys': 'parkways',

  'pass': 'pass',

  'passage': 'passage',
  'psge': 'passage',

  'path': 'path',

  'pike': 'pike',

  'pine': 'pine',
  'pne': 'pine',

  'pines': 'pines',
  'pnes': 'pines',

  'place': 'place',
  'pl': 'place',

  'plain': 'plain',
  'pln': 'plain',

  'plains': 'plains',
  'plns': 'plains',

  'plaza': 'plaza',
  'plza': 'plaza',
  'plz': 'plaza',

  'point': 'point',
  'pt': 'point',

  'points': 'points',
  'pts': 'points',

  'port': 'port',
  'prt': 'port',

  'ports': 'ports',
  'prts': 'ports',

  'prairie': 'prairie',
  'prr': 'prairie',
  'pr': 'prairie',
  
  'radial': 'radial',
  'radiel': 'radial',
  'radl': 'radial',
  'rad': 'radial',

  'ramp': 'ramp',

  'ranch': 'ranch',
  'rnchs': 'ranch',
  'rnch': 'ranch',

  'rapid': 'rapid',
  'rpd': 'rapid',

  'rapids': 'rapids',
  'rpds': 'rapids',

  'rest': 'rest',
  'rst': 'rest',

  'ridge': 'ridge',
  'rdge': 'ridge',
  'rdg': 'ridge',

  'ridges': 'ridges',
  'rdgs': 'ridges',

  'river': 'river',
  'rivr': 'river',
  'rvr': 'river',
  'riv': 'river',

  'road': 'road',
  'rd': 'road',

  'roads': 'roads',
  'rds': 'roads',

  'route': 'route',
  'rte': 'route',
  'rt': 'route',

  'row': 'row',

  'rue': 'rue',

  'run': 'run',
  'rn': 'run',

  'shoal': 'shoal',
  'shl': 'shoal',

  'shoals': 'shoals',
  'shls': 'shoals',

  'shore': 'shore',
  'shr': 'shore',

  'shores': 'shores',
  'shrs': 'shores',

  'skyway': 'skyway',
  'skwy': 'skyway',

  'spring': 'spring',
  'sprng': 'spring',
  'spng': 'spring',
  'spg': 'spring',

  'springs': 'springs',
  'spgs': 'springs',

  'spur': 'spur',

  'square': 'square',
  'sqre': 'square',
  'squ': 'square',
  'sqr': 'square',
  'sq': 'square',

  'squares': 'squares',
  'sqs': 'squares',

  'station': 'station',
  'statn': 'station',
  'stn': 'station',
  'sta': 'station',

  'stravenue': 'stravenue',
  'straven': 'stravenue',
  'strvnue': 'stravenue',
  'stravn': 'stravenue',
  'strvn': 'stravenue',
  'strav': 'stravenue',
  'stra': 'stravenue',

  'stream': 'stream',
  'streme': 'stream',
  'strm': 'stream',

  'street': 'street',
  'strt': 'street',
  'str': 'street',
  'st': 'street',

  'streets': 'streets',
  'sts': 'streets',

  'summit': 'summit',
  'sumit': 'summit',
  'sumitt': 'summit',
  'smt': 'summit',

  'terrace': 'terrace',
  'terr': 'terrace',
  'ter': 'terrace',

  'throughway': 'throughway',
  'trwy': 'throughway',

  'trace': 'trace',
  'trce': 'trace',

  'track': 'track',
  'trak': 'track',
  'trks': 'track',
  'trk': 'track',

  'trafficway': 'trafficway',
  'trfy': 'trafficway',

  'trail': 'trail',
  'trl': 'trail',

  'trailer': 'trailer',
  'trlr': 'trailer',

  'tunnel': 'tunnel',
  'tunl': 'tunnel',

  'turnpike': 'turnpike',
  'turnpk': 'turnpike',
  'trnpk': 'turnpike',
  'tpke': 'turnpike',

  'underpass': 'underpass',
  'upas': 'underpass',

  'union': 'union',
  'un': 'union',

  'unions': 'unions',
  'uns': 'unions',

  'valley': 'valley',
  'vally': 'valley',
  'vlly': 'valley',
  'vly': 'valley',

  'valleys': 'valleys',
  'vlys': 'valley',

  'viaduct': 'viaduct',
  'viadct': 'viaduct',
  'vdct': 'viaduct',
  'via': 'viaduct',

  'view': 'view',
  'vw': 'view',

  'views': 'views',
  'vws': 'views',

  'village': 'village',
  'villag': 'village',
  'villg': 'village',
  'vill': 'village',
  'vlg': 'village',

  'villages': 'villages',
  'vlgs': 'villages',

  'ville': 'ville',
  'vl': 'ville',

  'vista': 'vista',
  'vist': 'vista',
  'vsta': 'vista',
  'vst': 'vista',
  'vis': 'vista',

  'walk': 'walk',

  'wall': 'wall',

  'way': 'way',
  'wy': 'way',

  'well': 'well',
  'wl': 'well',

  'wells': 'wells',
  'wls': 'wells'
};

function remove_punctuation(item) {
  return item.replace(/[.,?:;!]/g, '');
}

function rd_compare(left, right) {
  left = remove_punctuation(left);
  right = remove_punctuation(right);
  
  const left_rd = roadmap[left.toLocaleLowerCase()];
  const right_rd = roadmap[right.toLocaleLowerCase()];
  
  if (left_rd && right_rd)
    return (left_rd||'').localeCompare(right_rd||'');
  
  return left.localeCompare(right, undefined, {sensitivity: 'base'});
}

function equal_without_whitespace(left, right, callback) {
  const a = left.split(/\s+/g);
  const b = right.split(/\s+/g);
  
  if (a.length !== b.length)
    return false;
  
  for (var idx = 0; idx < a.length; ++idx)
    if (callback) {
      if (callback(a[idx], b[idx]))
        return false;
    } else if (a[idx].localeCompare(b[idx], undefined, {sensitivity: 'base'}))
      return false;

  return true;
}

const addressesEqual = (left, right) => {
  var numberA = parseInt(left);
  var numberB = parseInt(right);

  if (numberA !== numberB)
    return false;

  var streetA = left;
  var streetB = right;
  var unitA = "";
  var unitB = "";
  var cityA = "";
  var cityB = "";
  var stateA = "";
  var stateB = "";
  var zipA = 0;
  var zipB = 0;

  // Matches the unit in the address
  const numberRegexp = /(\d+)([a-z]|(\s+\d+(\/\d+)?))?\s*(.*)/i;
  // Matches the unit, city, state, and zip
  const unitCityStateZipRegexp = /((,|:|;)?\s*(\bunit|\bsuite|\bste|\btrlr|#|\bapt\.?|\bapartment|\bnum\.?|\bnumber|\bno\.?)\s*([a-z]|((\d+\s+)?(\d+(\/\d+)?)[a-z]?)))?(,|:|;)\s*((\s*[a-z])+)(,|:|;)?\s+((\s*[a-z])+)\s+(\d{5})$/i;

  // Extract address number from street name
  var matchesA = streetA.match(numberRegexp);
  if (matchesA) {
    // numberA = Number(matchesA[1]);
    streetA = matchesA[5];
    unitA = matchesA[2] || "";
  }

  var matchesB = streetB.match(numberRegexp);
  if (matchesB) {
    // numberB = Number(matchesB[1]);
    streetB = matchesB[5];
    unitB = matchesB[2] || "";
  }

  // Extract unit from street name
  matchesA = streetA.match(unitCityStateZipRegexp);
  if (matchesA) {
    streetA = streetA.substring(0, streetA.length - matchesA[0].length).trim();
    unitA = matchesA[4] || unitA;
    cityA = matchesA[10];
    stateA = matchesA[13];
    zipA = Number(matchesA[15]);
  }

  matchesB = streetB.match(unitCityStateZipRegexp);
  if (matchesB) {
    streetB = streetB.substring(0, streetB.length - matchesB[0].length).trim();
    unitB = matchesB[4] || unitB;
    cityB = matchesB[10];
    stateB = matchesB[13];
    zipB = Number(matchesB[15]);
  }

  if (zipA !== zipB || 
      stateA.localeCompare(stateB, undefined, {sensitivity: "base"}) !== 0 || 
      !equal_without_whitespace(cityA, cityB) ||
      !equal_without_whitespace(streetA, streetB, rd_compare) ||
      !equal_without_whitespace(unitA, unitB))
    return false;

  return true;
}

const addressSortFirst = (left, right) => {
  if (left.territory !== right.territory)
    return (left.territory > right.territory? 1: 0) - (left.territory < right.territory? 1: 0);

  // Matches the unit in the address
  const unitRegexp = /((,|:|;)\s*)?(\bunit|\bsuite|\bste|\btrlr|#|\bapt\.?|\bapartment|\bnum\.?|\bnumber|\bno\.?)\s*((\d+\s+)?(\d+(\/\d+)?))$/i;
  // Matches the number in the address
  const numberRegexp = /(\d+)([a-z]|(\s+\d+(\/\d+)?))?\s*(.*)/i;

  var numberA = 0;
  var numberB = 0;
  var streetA = left.address;
  var streetB = right.address;
  var unitA = "";
  var unitB = "";

  // Extract address number from street name
  var matchesA = streetA.match(numberRegexp);
  if (matchesA) {
    numberA = Number(matchesA[1]);
    streetA = matchesA[5];
    unitA = matchesA[2] || "";
  }

  var matchesB = streetB.match(numberRegexp);
  if (matchesB) {
    numberB = Number(matchesB[1]);
    streetB = matchesB[5];
    unitB = matchesB[2] || "";
  }

  // Extract unit from street name
  matchesA = streetA.match(unitRegexp);
  if (matchesA) {
    streetA = streetA.substring(0, streetA.length - matchesA[0].length).trim();
    unitA = matchesA[4];
  }

  matchesB = streetB.match(unitRegexp);
  if (matchesB) {
    streetB = streetB.substring(0, streetB.length - matchesB[0].length).trim();
    unitB = matchesB[4];
  }

  var cmp = streetA.localeCompare(streetB, undefined, {sensitivity: "base"});
  if (cmp)
    return cmp;

  cmp = numberA - numberB;
  if (cmp)
    return cmp;

  return unitA.localeCompare(unitB, undefined, {sensitivity: "base"});
};

const fieldMatches = (test, search) => {
  return test.toLowerCase().indexOf(search.toLowerCase()) >= 0;
}

module.exports = {
  addressSortFirst: addressSortFirst,
  addressesEqual: addressesEqual,
  fieldMatches, fieldMatches
};